// Margin Bottom
.mb-10 {
  margin-bottom: var(--space-10);
}

.mb-10-important {
  margin-bottom: var(--space-10) !important;
}

.mb-15 {
  margin-bottom: var(--space-15);
}

.mb-20 {
  margin-bottom: var(--space-20);
}

.mb-23 {
  margin-bottom: var(--space-23);
}

.mb-24 {
  margin-bottom: var(--space-24);
}

.mb-25 {
  margin-bottom: var(--space-25);
}

.mb-30 {
  margin-bottom: var(--space-30);
}

.mb-40 {
  margin-bottom: var(--space-40);
}

.mb-50 {
  margin-bottom: var(--space-50);
}

.mb-60 {
  margin-bottom: var(--space-60);
}

// Margin Right
.mr-10 {
  margin-right: var(--space-10);
}

.mr-20 {
  margin-right: var(--space-20);
}

.mr-30 {
  margin-right: var(--space-30);
}

.mr-40 {
  margin-right: var(--space-40);
}

.mr-50 {
  margin-right: var(--space-50);
}

.mr-100 {
  margin-right: var(--space-100);
}

// Margin Left
.ml-3 {
  margin-left: var(--space-3);
}

.ml-10 {
  margin-left: var(--space-10);
}

.ml-33 {
  margin-left: var(--space-33);
}

.ml-reverse-15 {
  margin-left: -15px;
}
// Margin Right
.ml-20 {
  margin-left: var(--space-20);
}

.ml-30 {
  margin-left: var(--space-30);
}

.ml-60 {
  margin-left: var(--space-60);
}

.ml-80 {
  margin-left: var(--space-80);
}

// Margin Top

.mt-20 {
  margin-top: var(--space-20);
}

.mt-30 {
  margin-top: var(--space-30);
}

.mt-40 {
  margin-top: var(--space-40);
}

// Margin Top
.mt-10 {
  margin-top: var(--space-10);
}

.mt-15 {
  margin-top: var(--space-15);
}

.mt-25 {
  margin-top: var(--space-25);
}

.mt-50 {
  margin-top: var(--space-50);
}

.mt-60 {
  margin-top: var(--space-60);
}

.mt-75 {
  margin-top: var(--space-75);
}

.mt-10 {
  margin-top: var(--space-10);
}

.mt-120 {
  margin-top: var(--space-120);
}

.mt-130 {
  margin-top: var(--space-130);
}

//padding -left
.pl-13 {
  padding-left: var(--space-13);
}

.pl-30 {
  padding-left: var(--space-30);
}

.mt-43 {
  margin-top: var(--space-43);
}

//padding-right
.pr-30 {
  padding-right: var(--space-30);
}

//padding-bottom
.pb-20 {
  padding-bottom: var(--space-20);
}

//padding-top
.pt-20 {
  padding-top: var(--space-20);
}

.pt-30 {
  padding-top: var(--space-30);
}

.pt-40 {
  padding-top: var(--space-40);
}

.pt-50 {
  padding-top: var(--space-50);
}

.pt-60 {
  padding-top: var(--space-60);
}

.p-10 {
  padding: var(--space-10);
}

.ml-22 {
  margin-left: 22px;
}
