:root {
  --font-primary: 'Open Sans', sans-serif;
  --font-shilling: 'Unilever Shilling', 'UnileverShilling', 'Open Sans',
    sans-serif;

  /* set base values */
  --text-base-size: 16px;
  --text-base-line-height: 24px;
  --text-base-size-sm: 14px;
  --text-base-size-md: 15px;
  --text-base-icon-size-md: 32px;

  /* spacing */
  --space-5: 5px;
  --space-13: 13px;
  --space-15: 15px;
  --space-10: 10px;
  --space-20: 20px;
  --space-23: 23px;
  --space-24: 24px;
  --space-25: 25px;
  --space-30: 30px;
  --space-33: 33px;
  --space-40: 40px;
  --space-43: 43px;
  --space-50: 50px;
  --space-60: 60px;
  --space-75: 75px;
  --space-80: 80px;
  --space-90: 90px;
  --space-100: 100px;
  --space-110: 110px;
  --space-120: 120px;
  --space-130: 130px;

  /* colors */
  --color-dark: #2b2b2b;
  --color-base: #373a3c;
  --color-primary: #007dbb;
  --color-secondary: #043e5d;
  --color-heading: #232e83;
  --color-grey: #cccccc;
  --color-table: #f9f9f9;
  --color-error: #df1642;
  --color-processing: #f0ad4e;
  --color-draft: #5bc0de;
  --color-cancelled: #818a91;
  --color-completed: #5cb85c;
  --color-green: #1db949;
  --color-border: #dfe2e7;
  --color-border-btn: #043e5d;
  --color-search-border: #e7eaeb;
  --color-grey-2: #edeaea;
  --color-background-btn-secondary: #e9e9e9;
  --color-background-pill: rgba(211, 211, 211, 0.6);
  --color-highlighter: #9a33a0;
  --color-highlighter-email: #ff0f7d;
  --color-code: #f2f2f2;
  --color-menu-grey: #f4f4f4;
  --color-background: rgba(237, 234, 234, 0.8);
  --color-text-light: rgba(112, 112, 112, 1);
  --color-input-border: #007bff40;
  --color-input-border-highlight: #007bff40;
  --color-input-border-highlight-invalid: #dc354540;
  --color-white: #fff;
  --color-grey-line: #dbdbdb;
  --color-grey-number: #707070;
  --color-black: #000;
  --color-text-grey-1: #7d7d7d;
  --color-text-grey-2: #9d9d9d;
  --color-email-progress-bar-1: #da3eb8;
  --color-email-progress-bar-2: #ff0f7d;
  --color-email-dark-pink: #e60067;
  --color-range-slider-grey: #d9d9d9;
  --color-tab-border: #aaa;
  --color-grey-8: #9d9d9d;
  --color-orange-1: #ff9900;
  --color-orange-2: #ffb02e;
  --color-email-warning: #ffc001;
  --color-text-grey-3: #707070;
  --color-green-pill: #00ca59;
  --color-toggle-button: #4cb9ea;
  --color-email-badge-info: #ffc000;
  --color-campaign-disabled-text: #949697;
  --color-campaign-disabled-link: #79b8d9;
  --color-alert-warning: #ffeeba;
  --color-alert-warning-border: #fff3cd;
  --color-alert-warning-text-color: #856404;
  --color-disable-input: #e9ecef;
  --color-light-grey: #fafafa;
  --color-border-grey: #ddd;

  /* typography */
  --font-size-h1: 36px;
  --font-size-h2: 24px;
  --font-size-h3: 18px;
  --font-size-xsmall: 11px;
  --font-size-subheading: 20px;

  --line-height-h1: 49px;
  --line-height-h2: 33px;
  --line-height-h3: 24px;

  --text-large: 16px;
  --text-small: 14px;
  --text-xsmall: 12px;

  --line-height-large: 22px;
  --line-height-small: 19px;
  --line-height-xsmall: 12px;
  --line-height-xsmall-spaced: 16.34px;
  --line-height-xxsmall: 15px;

  --font-weight-semi-bold: 600;

  /* border colors */
  --color-border-table: #eceeef;
  --color-schedule: #00d7c4;
  --color-awiting-setup: #7705bc;
  --color-awiting-purchase-order: #ff9998;
  --color-light-grey: #fafafa;
}
