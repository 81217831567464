h1,
h2,
h3,
h4 {
  color: var(--color-text-heading);
  line-height: var(--heading-line-height);
  margin: 0;
}

h1 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}

.xsmall-label {
  font-size: var(--font-size-xsmall);
}

/* Custom Headings */
.cw-heading--primary {
  color: var(--color-primary);
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  font: normal normal 600 20px/27px Open Sans;

  &--homepage-heading {
    text-align: center;
    font-size: 21px;
    width: 91%;
    padding: 0 0 0 48px;
    color: #007dbb;
  }

  &--qna-reboot {
    font-size: 21px;
    font-weight: 600;
  }
}

.cw-heading--secondary {
  color: var(--color-heading);
  font-size: var(--font-size-h1);
  font-weight: 400;
  line-height: var(--line-height-h1);
}

.cw-heading--tertiary {
  color: #373a3c;
  font-size: 24px;
  line-height: 31px;
  font-weight: 400;
}

.cw-heading--quinary {
  color: #373a3c;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;

  &:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f013';
  }
}

.cw-heading--senary {
  color: var(--color-primary);
  // font-size: 24px;
  line-height: 33px;
  font-weight: 400;
  font: normal normal 600 20px/27px Open Sans;
}

.cw-heading-qna {
  color: #007dbb;
  font-size: 16px;
  font-weight: 400;
}

.cw-heading--tenary {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-weight: 700;
}

.cw-heading--usermgmt {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-weight: 400;
  float: right;
}

.cw-heading--saascampaign {
  color: #007dbb;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
}

.cw-modal .cw-subheading--saascampaign {
  font-size: var(--text-base-size-sm);
  font-weight: normal;
  margin-top: var(--space-20);
  margin-bottom: var(--space-10);
  max-width: 90%;
}

.cw-heading--qnafilters {
  color: #007dbb;
  font-size: 16px;
  font-weight: 600;
  line-height: 10px;
}

.cw-heading--qnatile {
  color: orange;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
}

.cw-heading--saasconsent {
  color: #007dbb;
  font-size: 22px;
  font-weight: 600;
  line-height: 10px;
}

// Text Styles
.cw-text--quinary {
  font-size: 14px;
  line-height: 24px;
}

.bold-text {
  font-weight: bold;
}

.alert-update-message {
  display: inline-flex;

  .saas-update-icon {
    flex: 3;
    margin-top: 2%;
    @media (max-width: 1024px) {
      flex: 4;
    }
    .icon-span {
      background-color: #ad19cc;
      border-radius: 50%;
      margin-right: 5px;
      padding: 1px 3px 1px 3px;
      text-align: center;
      &:before {
        content: '\f005';
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
        color: white;
      }
    }
  }

  .saas-update-content {
    flex: 9;
  }
}

.alert-qna-message {
  display: block;
  background-color: #ffffff;
  border: 1px solid #ad19cc;
  color: #ad19cc;
  .saas-update-icon {
    flex: 3;
    margin-top: 2%;
    margin-bottom: 2%;
    .icon-span {
      background-color: #ad19cc;
      border-radius: 50%;
      margin-right: 5px;
      padding: 1px 3px 1px 3px;
      &:before {
        content: '\f005';
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
        color: white;
      }
    }
  }

  .saas-update-content {
    flex: 9;
    margin-bottom: 2%;
  }
}

.alert-qna-message {
  display: block;
  background-color: #ffffff;
  border: 1px solid #ad19cc;
  color: #ad19cc;
  .saas-update-icon {
    flex: 3;
    margin-top: 2%;
    margin-bottom: 2%;
    .icon-span {
      background-color: #ad19cc;
      border-radius: 50%;
      margin-right: 5px;
      padding: 1px 3px 1px 3px;
      &:before {
        content: '\f005';
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
        color: white;
      }
    }
  }

  .saas-update-content {
    flex: 9;
    margin-bottom: 2%;
  }
}

.saas-warning {
  border: none;
  border-radius: 7px;
  background-color: #f5e6e6;
  color: #ca0c1d;
  padding: 10px 20px 12px;
  font-size: 15px;
  opacity: 0.8;

  i {
    margin-top: 5px;
  }
}

.accordian-left {
  margin-left: auto;
}

.saas-accordion-arrow {
  float: right;
  border-left: 1px solid #cccccc;
  padding-left: 15px;
  color: #cccccc;
}

.transformIcon-180 {
  transform: rotate(180deg);
}

.card-bottom-border {
  border-bottom: 1px solid #007dbb;
}
.card-no-bottom {
  border-bottom: 0px;
}
.afterTransform {
  border-left: 1px solid #007dbb !important;
}

.transformProfileIcon-180 {
  transform: rotate(180deg);
  color: #007dbb;
}

.saas-form-field {
  & .disabled {
    color: var(--color-cancelled);
  }
  & .enabled {
    color: var(--color-primary);
  }
}

.saas-accordion-close {
  float: right;
  font-size: 20px;
  position: absolute;
  right: -30px !important;
  z-index: 99;
  &:after {
    content: '\f057';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
    color: var(--color-primary);
  }
  &.disabled:after {
    color: #cccccc;
  }
  &.qna {
    top: 5px;
  }
  &.incentive {
    top: 4%;
  }
  &.epsilon-qna {
    margin-top: -6%;
  }
  &.epsilon-qna-mtop {
    margin-top: -7%;
  }
  &.epsilon-qna-close {
    margin-top: 4%;
    // margin-top: -1.3%;
  }

  &--manage-edtors {
    margin-top: -12%;
    right: -7%;
  }
  &.non-prod-url {
    top: 5px;
  }
}

.saas_new_master_logo {
  float: right;
  font-size: 15px;
  position: absolute;
  right: -60px;
  top: 14px;
  z-index: 1;
}

.saas_new_master_logo_old {
  right: -150px;
}

.saas_new_master_logo_questionsanswers {
  font-size: 15px;
  // position: absolute;
  // right: 5px;
  // margin-top: -25px;
  margin: -3px 0 0px 8px;
}
.saas_new_master_tooltip {
  width: auto !important;
}

.manage-editors {
  float: right;
  font-size: 20px;
  position: absolute;
  right: -7%;
  top: 4px;
  &:after {
    content: '\f055';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
    color: var(--color-primary);
  }
  &.disabled:after {
    color: #cccccc;
  }
}

.saas-form-authoring {
  .collapse {
    width: 95%;
  }
  .cw-select--custom {
    .css-bg1rzq-control {
      border: 0;
      background-color: #f7f7f7;
      .css-151xaom-placeholder {
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}

.form-group-field-width {
  width: 99%;
  margin: 2% 0 2% 1%;
}

.cw-promotigosubCategory {
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: var(--color-campaign-disabled-text);

    .title {
      font-weight: var(--font-weight-semi-bold);
    }

    p,
    .email-sendafter-font {
      font-size: var(--text-large);
    }
    p {
      color: var(--color-campaign-disabled-text);
    }
    .email-sendafter-font {
      color: var(--color-base);
    }

    .alert-update-message {
      color: var(--color-campaign-disabled-link) !important;
      font-size: var(--text-large);
    }

    img {
      opacity: 0.6;
    }
  }
}

.cw-qna-lable-width {
  margin-left: 30px !important;
  position: relative;
  .cw-new-question-dropdown.badge {
    top: 40px;
  }
}
.cw-qna-group-field-width {
  margin: 2% 0 2% 1%;
}

.generic-error-message {
  width: 99.5%;
  font-weight: 600;
}

.saas-profile-select {
  width: 100%;
  font-weight: 700;
  &--qna-new-design {
    width: 100%;
  }
  .css-1jqq78o-placeholder,
  .css-1dimb5e-singleValue {
    font-weight: normal;
    color: #808080;
  }
  .css-166bipr-Input,
  .css-19bb58m,
  .css-19bb58m-Input {
    display: flex;
    font-weight: normal;
  }
}

.toolbar-component {
  border: none !important;
  overflow-y: hidden !important;
  margin-left: -2% !important;

  &--tool_span {
    background-color: #fff !important;

    &:hover {
      color: var(--color-primary) !important;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &--vr {
    border: 0.1px solid #707070;
    height: 20px;
    width: 0px;
    margin-top: 2.3%;
  }

  &__icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .w-35 {
    width: 35px;
  }
}

.vr {
  border-left: 3px solid #ccc;
  height: 140px;

  &--vr-height {
    height: auto;
  }

  &--vr-qnaheight {
    height: 110px;
  }
}

.summary-component {
  width: 100%;
  height: auto;
  border: 2px solid #0074bd;
  border-radius: 10px;
  opacity: 1;
  margin: 0 !important;

  &--summary-component-height {
    height: auto;
  }
  &--summary-component-form-section {
    height: auto;
    margin-bottom: 30px !important;
  }
  .vr {
    height: auto;
  }
}

.summary-component-qna {
  width: 100%;
  height: auto;
  border: 2px solid orange;
  border-radius: 10px;
  opacity: 1;

  &--summary-component-height {
    height: auto;
  }
  &--summary-component-form-section {
    height: auto;
  }
}

.summary-component-message {
  display: inline-flex;
  width: 100%;
  padding: 1% 3%;
  border-radius: 10px;
  margin-bottom: 10px;

  .summary-component-icon {
    flex: 3;
  }

  .summary-component-content {
    flex: 3;
  }
}

.summary-component-img {
  margin: 0 0 -3% 4%;
}

.summary-campaign-info {
  margin: 0 -3% -2% -2%;
}

div.toolbar-component > span:last-child {
  display: none;
}

.btn-summary-preview a {
  width: 90%;
  @media (max-width: 1024px) {
    width: auto;
    padding: 0 16px;
  }
}

.footer_link {
  margin: 4px;
}
.footer-row {
  grid-template-columns: auto auto auto minmax(185px, 1fr) !important;
}

.footer-column--logo {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 4rem;
}

.footer-column--menu {
  margin-top: 4px;
}

.footer-column--feedback {
  align-self: flex-start;
}

.footer_logo_container {
  display: flex;
  align-items: flex-end;
}

.footer_img_logo {
  object-fit: scale-down;
}

.footer_img {
  bottom: 0;
  right: 0;
  min-width: 220px;
  height: 209px;
  position: absolute;
}

.qna-guideline-close {
  float: right;
  &:after {
    content: '\f057';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
    color: var(--color-primary);
  }
  &.disabled:after {
    color: #cccccc;
  }
}

.guideline-accordion-arrow {
  padding-left: 10px;
  color: var(--color-primary);
}

.qna-tab-overlay {
  height: 1vh !important;
  width: 92% !important;
  overflow-y: hidden !important;
  margin: 6% 0 0 2% !important;
  //margin-left: 2% !important;
}

.qna-tab-content {
  cursor: pointer !important;
  width: 25% !important;

  &--active {
    color: #007dbb !important;
  }
}

.manage-non-prod-url {
  float: right;
  font-size: 20px;
  position: absolute;
  right: -30px;
  top: 5px;
  &:after {
    content: '\f055';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
    color: var(--color-primary);
  }
  &.disabled:after {
    color: #cccccc;
  }
}

.fake-input {
  position: relative;
  width: 100%;
}
// .fake-input input { border:none; background-color:#fff; display:block; width: 100%; box-sizing: border-box }
.fake-input img {
  position: absolute;
  top: 4px;
  margin-left: 50%;
}

.draft_button_section_main .toolbar-component span {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  border-color: #afacac;
}
.draft_button_section_main .toolbar-component.menu {
  display: flex;
  margin: 0 0 0 -10px !important;
  height: unset !important;
  position: unset;
  margin-bottom: 30px !important;
  align-items: center;
  box-shadow: none;
}
.draft_button_section_main .toolbar-component.menu .toolbar-component--vr {
  margin: 0 0 0 10px !important;
}
.draft_button_section_main .toolbar-component.menu span a {
  padding: 0 !important;
  line-height: unset !important;
}
.draft_button_section_main .toolbar-component.menu span a {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.draft_button_section_main .toolbar-component.menu span div {
  padding: 0 !important;
  line-height: unset !important;
  cursor: default;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.draft_button_section_main .toolbar-component.menu span a:before {
  content: none !important;
}
.draft_button_section_main .toolbar-component.menu span div:before {
  content: none !important;
}
.draft_button_section_main .draft_button_section {
  margin: 0 !important;
  float: none;
  overflow: hidden;
}

.overview-toolbar .toolbar-component.menu {
  margin-bottom: 10px !important;
}

.cw-question-answer-v2 .col-sm-3.text-center {
  width: 230px !important;
  flex: 1 !important;
  max-width: unset !important;
  display: inline-block !important;
}
.cw-question-answer-v2 .col-sm-3.text-center button {
  width: 100% !important;
  margin-left: 15px !important;
}
.request-new-modal-header {
  margin-bottom: 20px;
}
.modal-header-back i {
  margin-right: 10px;
  font-size: 15px;
}
.modal-header-back span {
  color: rgba(0, 0, 0, 0.88);
}
.add-new-question-body h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  margin-bottom: 5px;
}
.note-question {
  margin-bottom: 10px;
}
.addnew-question-text h6 {
  color: rgba(0, 0, 0, 71);
  font-size: 17px;
  font-weight: 600;
  margin: 15px 0 2px !important;
}
.addnew-question-text {
  margin-bottom: 25px;
  p {
    font-size: 15px;
  }
}
.form-controler .form-group {
  display: flex;
  align-items: center;
  input {
    height: 45px;
    width: 100%;
    max-width: 70%;
  }
  .sufix {
    flex-shrink: 0;
    margin-left: 15px;
    color: #e9a40a;
    i {
      background: #e9a40a;
      font-size: 10px;
      width: 23px;
      height: 23px;
      text-align: center;
      line-height: 24px;
      border-radius: 100%;
      color: white;
    }
  }
}
.cw-modal--addqnadetail {
  max-width: 900px !important;
  padding: 16px !important;
  border: 1px solid #707070;
  border-radius: 0 !important;
}

.devider-top {
  text-align: right;
  border-top: 1px solid #007dbb;
  padding-top: 20px;
  // margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin-right: 20px;
    color: #008561 !important;
  }
  button[disabled] {
    background: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    color: #fff;
  }
}
.devider-top-answer {
  text-align: right;
  border-top: 1px solid #007dbb;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  margin-top: 50px;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
  p {
    margin-right: 20px;
    color: #008561 !important;
  }
  button[disabled] {
    background: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    color: #fff;
  }
}

.form-controler {
  // max-height: 300px;
  // overflow-y: auto;
  padding-right: 10px;
  padding-left: 2px;
}

.form-controler #ReasonForMasterdata,
.form-controler #translatedLabel,
.form-controler #englishLabel {
  top: -8px;
  font-size: 15px;
}

.form-controler .form-group .cw-select--custom {
  width: 100%;
  max-width: 70%;
}
.form-controler
  .form-group
  .cw-select--custom
  > div:first-child
  > div:first-child
  > div:first-child {
  color: #808080;
  font-weight: 400;
}
.form-controler
  .form-group
  .cw-select--custom
  span[aria-live='assertive']
  + div
  > div
  > div {
  font-weight: 400 !important;
  color: #808080 !important;
}
.form-controler
  .form-group
  .cw-select--custom.addded
  span[aria-live='assertive']
  + div
  > div
  > div,
.form-controler
  .form-group
  .cw-select--custom.addded
  > div:first-child
  > div:first-child
  > div:first-child {
  color: #495057 !important;
}
.form-controler .saas-profile-select--qna-new-design {
  width: 100%;
  align-items: flex-start;
  .cw-icon--help {
    margin-top: 10px;
  }
}
.search-sufix {
  position: absolute;
  right: 10px;
  top: 11px;
  color: #777;
}
.langtext-wrap {
  position: relative;
  width: 55%;
}
.modal-header-back {
  cursor: pointer;
}
.note-question {
  color: #c71f1f;
}
.request-new-modal-header-warp {
  margin-bottom: 20px;
}
.request-new-modal .request-new-modal-header .modal-header-back span {
  display: flex;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
}
.custom-free-text h6 {
  line-height: 26px;
}
.request-new-modal .form-controler .form-group {
  justify-content: space-between;
}
.request-new-modal .qna-guideline-close {
  position: absolute;
  top: 20px;
  right: 20px !important;
}
.form-controler .saas-profile-select--qna-new-design .cw-icon--help {
  margin-left: 10px;
}
.cw-modal--addqnadetail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.qnaNewOverlay .btn[disabled] {
  background: rgba(0, 0, 0, 0.33);
  border-color: transparent;
  color: #fff;
}
.search-master-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.search-master-id input {
  height: 40px;
}
.form-controler .cw-select--custom input {
  height: 33px;
}
.addnew-Answer-text {
  margin-bottom: 15px;
}
.answer-section .cw-error {
  margin: -13px 0 13px 0;
}
// .ReactModal__Overlay .answer-model{
//   height: auto;
// }
.devider-top-answer-list {
  text-align: right;
  border-top: 1px solid #007dbb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 20px;
  margin-left: 8px;
  p {
    margin-right: 20px;
    color: #008561 !important;
  }
  button[disabled] {
    background: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    color: #fff;
  }
}
.button-message {
  display: flex;
  align-items: center;
  p {
    margin-right: 15px;
  }
}
.filter-buttons {
  border-radius: 8px;
  width: auto;
  font-size: 12px;
  background: #e2e2e2;
  border-color: #e2e2e2;
  color: #000;
  box-shadow: none !important;
  outline: none !important;
  line-height: 33px;
  margin-right: 5px !important;
  padding: 0px 9px;
  &.active {
    background: #0076bd;
    color: #fff;
  }
}
.qna-guidline-btn-wrap {
  display: flex;
  height: calc(100vh - 120px);
  flex-direction: column;
  justify-content: space-between;
  // @media(max-width: 1100px){
  //   height: calc(70vh - 120px);

  // }
  &.redice-height {
    height: calc(93vh - 175px);
    // @media(max-width: 1100px){
    //   height: calc(85vh - 190px);
    // }
  }
}
.qna-guidline-btn-wrap-2 {
  display: flex;
  height: calc(100vh - 50px);
  flex-direction: column;
  justify-content: space-between;
  // @media(max-width: 1100px){
  //   height: calc(80vh - 50px);

  // }
}
.add-space {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 50px);
  // @media (max-width:1100px) {
  //   height: calc(70vh - 50px);
  // }
}
.redice-height .qna-guideline-scroll {
  margin-top: 1%;
  @media (max-width: 1100px) {
  }
}
.redice-height .devider-top-answer-list {
  margin-top: 20px;
  margin-bottom: 0;
  padding-top: 15px;
}
.light-placeholder {
  font-weight: 400 !important;
}
.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.cw-modal--qnaoverlay {
  overflow-y: auto !important;
  max-width: 758px;
}
.saas-form-field-accordion-qna {
  .saas-accordion-heading {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 97%;
  }
}
.saas-accordion-heading {
  max-width: 90%;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.saas-profile-select--qna-new-design {
  .cw-select--custom > span + div + div {
    position: unset;
  }
}

.guideline-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-form-btn {
  padding: 0 50px;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
}
.qna-guideline-close-modal {
  position: absolute;
  right: -3px;
}

.email-preview-close-modal {
  right: -32px;
  top: -10px;
}

.brand-details-close-modal {
  position: absolute;
  right: 0px;
  top: 10px;
}
.qna-guidline-btn-wrap .form-check-label {
  font-size: 14px;
}
.qna-guidline-btn-wrap .horizontal-line--li {
  margin-bottom: 1px !important;
}
.cw-modal--qnaoverlay .qna-guideline-close {
  font-size: 20px;
}
input.form-control[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: linear-gradient(
      45deg,
      rgba(0, 225, 187, 0) 0%,
      rgba(0, 225, 187, 0) 43%,
      #007dbb 45%,
      #007dbb 55%,
      rgba(0, 225, 187, 0) 57%,
      rgba(0, 225, 187, 0) 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 43%,
      #007dbb 45%,
      #007dbb 55%,
      transparent 57%,
      transparent 100%
    );
}

.custon-alert-msg span {
  display: flex;
  i {
    padding-top: 5px;
  }
}
.cw-heading--tenary-sub-text {
  font-size: 14px;
  color: red;
  font-weight: bold;
}
.choose-request-type-new .alert-qna-message {
  margin-top: 160px !important;
  margin-left: 0 !important;
}

.cia-list {
  padding-left: 26px;
  padding-top: 15px;
}

.cia-list-review {
  padding-left: 26px;
}

.alert-cia-message {
  display: block;
  background-color: #ffffff;
  border: 1px solid #ad19cc;
  color: #ad19cc;
  .saas-update-icon {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    align-items: center;
    .icon-span {
      margin-right: 5px;
    }
  }

  .saas-update-content {
    flex: 9;
    margin-bottom: 2%;
  }
}

.cia-new-icon {
  margin-left: 10px;
  margin-top: -2px;
}

.cia-info-box {
  padding-top: 8px;
  span {
    font-size: 32px;
  }
}
.custon-alert-non-saas .alert {
  margin-right: 0 !important;
}
.custom-tag-value-advance-filter .custom-tag-value {
  max-width: 95% !important;
}
#refresh-status.__react_component_tooltip.cw-tooltip {
  width: auto;
  padding: 1%;
}

.alert-new-feature-mesg {
  display: block;
  background-color: #ffffff;
  border: 1px solid #ad19cc;
  color: #ad19cc;
  border-radius: 10px;
  max-width: 85%;
  margin-left: auto;
  .saas-update-icon {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    align-items: center;
    .icon-span {
      margin-right: 5px;
    }
  }

  .saas-update-content {
    flex: 9;
    margin-bottom: 2%;
    p {
      margin: 10px 0;
      font-size: 14px;
      color: #000;
      text-align: left;
    }
  }

  .alert-mesg-link {
    text-decoration: underline;
    color: #007dbb;
  }
}

.cw-modal--feedbackform {
  overflow-y: auto !important;
  max-width: 758px;
}

.cw-modal--beeSaveRow {
  // overflow-y: auto !important;
  overflow: auto !important;
  max-width: 758px;
  width: 560px;
  max-height: 90%;
}

.cw-modal--template-preview {
  overflow-y: auto !important;
  max-width: 758px;
  width: 435px;
  max-height: 90%;
  display: flex;
  overflow-x: hidden !important;
}

.cw-modal--save-template {
  overflow-y: auto !important;
  //overflow: visible !important;
  max-width: 758px;
  width: 560px;
  //max-height: 90%;
}

.cw-modal--filePicker-info {
  overflow: auto !important;
  width: 758px;
  max-height: 90%;
  b {
    color: var(--color-primary);
  }
  i.copy {
    cursor: pointer;
  }
}

.cw-modal--abtest-guidelines {
  // overflow-y: auto !important;
  overflow: auto !important;
  max-width: 900px;
  width: 900px;
  max-height: 90%;
}

.cw-btn-active {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.satisfactory_rating_wrapper {
  .rating-input {
    [type='radio'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    label {
      margin-bottom: 0px;
    }

    [type='radio'] + img,
    span {
      cursor: pointer;
    }

    [type='radio']:checked + img {
      border: 2px solid var(--color-primary);
      border-radius: 50%;
    }

    span {
      display: block;
      text-align: center;
    }
  }

  .rating-help-text {
    width: 400px;
  }
}

.brand-detail-alert .alert-qna-message {
  margin-top: 32px !important;
}
.cns-campaign .alert-qna-message {
  margin-top: 0 !important;
}

.approver-action {
  min-width: 100px;
}
.toolbar-scroll-fix {
  flex: 100% !important;
  max-width: 100% !important;
}
.accordian-card-header {
  width: 100%;
  border: 0px;
  display: flex;
  justify-content: space-between;
}
.approver-action {
  min-width: 100px;
}
.report-text-display {
  max-width: 50%;
}
.preview-form-btn-max-width {
  max-width: 85%;
}

.stats-box-title {
  font-weight: 400;
}

.preview-loader {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  position: absolute;
  top: 13px;
}
.new-question-answer-btn {
  color: var(--color-primary);
  cursor: pointer;
}
.campaign-trends {
  border: 1px solid #cac6c6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.campaign-trends-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 18px;
  align-items: center;
}

.campaign-trends-chart {
  height: 150px;
}

.campaign-trends-filter-dropdown {
  //width: 130px;
  border-radius: 10px;
  font-size: 14px;
}
.all-campaign {
  // width: 268px;
  height: 158px;
  margin-left: 10%;
  margin-right: 10%;
  background: #6e8a82;
  border: 2px solid #6e8a82;
  border-radius: 15px;
  .title {
    color: #ffffff;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    background: #ffffff;
    border: 2px solid #6e8a82;
    border-radius: 15px;
    font-weight: 700;
    font-size: 28px;
    color: #707070;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .percentage {
    font-size: 18px;
    color: #9d9d9d;
  }
}

.cw-dashboard-summary-card-rings {
  height: 148px;
  border-radius: 6px;
  background-color: #350949;
  background-image: url('../../public/campaign-wizard/widget_bg_1.png');
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 20px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    padding-top: 16px;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-brand-market {
  height: 148px;
  border-radius: 6px;
  background-color: #04354a;
  background-image: url('../../public/campaign-wizard/world-2.png');
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-markets {
  height: 148px;
  border-radius: 6px;
  background-color: #0d1c52;
  background-image: url('../../public/campaign-wizard/city.png');
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-brands {
  height: 148px;
  border-radius: 6px;
  background-color: #a90f47;
  background-image: url('../../public/campaign-wizard/leaf.png');
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-languages {
  height: 148px;
  border-radius: 6px;
  background-color: #105b4e;
  background-image: url('../../public/campaign-wizard/world-1.png');
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-requestors {
  height: 148px;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    rgba(255, 113, 155, 0.8) 34.38%,
    rgba(253, 223, 166, 0.8) 100%
  );
  //background: linear-gradient(180deg, rgba(255, 113, 155, 0.8) 34.38%, rgba(253, 223, 166, 0.8) 100%), url('/campaign-wizard/requestor.png');
  background-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-approvers {
  height: 148px;
  border-radius: 6px;
  //background: linear-gradient(180deg, rgba(136, 155, 255, 0.8) 0%, rgba(253, 220, 240, 0.8) 100%), url('/campaign-wizard/approvers.png');
  background: linear-gradient(
    180deg,
    rgba(136, 155, 255, 0.8) 0%,
    rgba(253, 220, 240, 0.8) 100%
  );
  background-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}
.cw-dashboard-summary-card-data-bureau {
  height: 148px;
  border-radius: 6px;
  //background: linear-gradient(180deg, #C97BFA 0%, rgba(248, 136, 190, 0.6) 100%), url('/campaign-wizard/data-bureau.png');
  background: linear-gradient(
    180deg,
    #c97bfa 0%,
    rgba(248, 136, 190, 0.6) 100%
  );
  background-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.cw-dashboard-summary-card-admin {
  height: 148px;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    #ff6da2 0%,
    rgba(253, 134, 255, 0.6) 100%
  );
  //background: linear-gradient(180deg, #FF6DA2 0%, rgba(253, 134, 255, 0.6) 100%), url('/campaign-wizard/admins.png');
  background-blend-mode: darken;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-position: right;
  background-position-y: bottom;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  .title {
    color: #ffffff;
    margin-left: 16px;
    display: inline-flex;
    font-size: 14px;
  }
  .total-count {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 70%;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
  }
  .percentage {
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
  }
}

.dashboard-secondary-card {
  margin-top: 16px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 6px;
  font-size: 12px;
  box-shadow: none;
}

.campaign-type-chart {
  width: 95%;
  height: 100px;
}
.campaign-trends-chart-height {
  height: 250px !important;
}

.chart-summary-wrapper {
  padding: 0px 40px;
  .chart-summary-separator {
    display: unset !important;
    .report-heading-line {
      background: #007dbb;
      height: 2px;
      margin: 8px 0px;
    }
  }

  .cell-value {
    font-size: var(--text-small) !important;
    color: #000;
  }

  .highlight {
    color: #007dbb !important;
    font-size: 14px;
  }

  .checkbox-label-small {
    font-size: 12px;
  }
}

.chart-summary-wrapper-no-padding {
  padding: 0px 0px !important;
  width: 100%;
  .chart-summary-separator {
    display: unset !important;
    .report-heading-line {
      background: #007dbb;
      height: 2px;
      margin: 8px 0px;
    }
  }

  .cell-value {
    font-size: var(--text-small) !important;
    color: #000;
  }

  .highlight {
    color: #007dbb !important;
    font-size: 14px;
  }

  .checkbox-label-small {
    font-size: 12px;
  }
}

.summary-padding {
  padding: 0px 60px;
}
.preview-btn-padding {
  padding: 2px;
}
.dashboard-title-icon-image {
  width: 28px;
  height: 28px;
}

.dashboard-info-security {
  display: flex;
  //align-items: flex-end;
  justify-content: center;
  padding-top: 64px;
}
.dashboard-info-security-title {
  width: 22% !important;
}

.email-test-field-width {
  width: 89%;
  margin: 2% 0 2% 1%;
}
.email-text-font {
  font-size: 12px;
}
.email-test-close-modal {
  position: absolute;
  right: -3px;
  top: -4px;
}
.email-summary-font {
  font-size: 14px;
}

.email-sendafter-font {
  font-size: 14px;
  color: var(--color-grey-number);
}

.select-email-type-wrapper {
  border: 2px solid var(--color-range-slider-grey);
  border-radius: 5px;
  padding: 8px 22px 8px 22px;
  &.selected,
  &:hover {
    border-width: 2px;
    border-color: #007dbb;
  }
}

.select-email-label {
  display: flex !important;
  gap: 16px;
}
.email-type-desc-font {
  font-size: 14px;
  color: var(--color-grey-number) !important;
}

.progress-bar-css {
  background-image: linear-gradient(
    var(--color-email-progress-bar-1),
    var(--color-email-progress-bar-2)
  );
}
.cw-email-heading {
  font-size: var(--font-size-email-h1);
}

.cw-modal--schedule-email-model .cw-email-heading {
  line-height: normal;
  margin-top: 5px;
}

.cw-email-progress-custom {
  width: 80%;
  border-radius: 1.25rem;
}

.email-campaigns-navigation-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-tab-border);
  .react-tabs__tab-list {
    width: 100%;
  }
}

.email-campaigns-navigations {
  // margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 40px;

  a {
    white-space: nowrap;
  }

  button {
    white-space: nowrap;
    margin-bottom: 2px;
    height: 29px !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
  }
}
.cw-answer-answer {
  margin-top: 10px;
  display: block;
}
.cw-report-dashboard-wrapper {
  padding-left: 10%;
  padding-right: 10%;
}

.cw-chart-toggle-title {
  font-size: 12px;
}
.cw-chart-footnote-wrapper {
  display: flex;
  justify-content: end;
}

.cw-chart-footnote {
  font-size: 12px;
}

.cw-chart-table-right-button {
  font-size: 12px;
  font-weight: bold;
  color: #0074bd;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
}

.cw-report-dashboard-dropdown-compact {
  min-width: 165px;
  height: 38px;
}

.approver-assigment-info {
  display: flex;
  align-items: center;
  .assign-icon {
    margin-right: 10px;
  }
  p {
    font-size: 16px;
    color: var(--color-primary) !important;
  }
}
.footer-row {
  align-items: flex-start !important;
}
.email-tab-text {
  margin-top: 10px !important;
}

.text-blink {
  border-radius: 5px;
  animation-name: blinktxt;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes blinktxt {
  0% {
    background-color: var(--color-completed);
  }
  // 50% {background-color: var(--color-processing)}
  100% {
    background-color: var(--color-completed);
  }
}

.masterdata-overlay {
  color: #007dbb;
  font-size: 14px;
  font-weight: 600;
  line-height: 10px;
}

.margin-top-none {
  margin-top: 0px !important;
}

.email-label-subtext {
  font-size: 12px;
  color: var(--color-grey-number);
}

.user-management-country-list {
  gap: 24px;
  margin-top: 16px;
  border-bottom: 1px solid rgba(79, 79, 79, 0.5);
}

.user-management-business-grp-list {
  // gap: 16px;
}

.user-country {
  gap: 16px;
}

.user-managment-usercountry {
  flex: 1;
}

.manage-non-url {
  position: relative;
  font-size: 20px;
  right: unset !important;
  top: unset !important;
}

.user-management-business-grp-list-label {
  white-space: nowrap;
}

.user-management-country-label {
  width: 80px;
  flex-shrink: 0;
}
.email-request-info-text-area {
  &::placeholder {
    color: rgba(112, 112, 112, 0.5);
    font-size: 14px;
  }
}
.content-email-column {
  max-width: 220px;
}
.content-approver-search {
  width: 340px;
}

.table-approverlist {
  background-color: transparent !important;
  padding: 0px !important;
  border: 1px solid rgba(79, 79, 79, 0.5);

  thead {
    tr {
      //padding: 0px !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    th {
      border-top: none !important;
    }
  }
  tbody {
    tr {
      &:first-child {
        td,
        th {
          border-top: none !important;
        }
      }
    }
    td,
    th {
      border-top: 1px solid rgba(79, 79, 79, 0.5) !important;
      border-radius: 0 !important;
    }
  }

  //  tbody {
  //   td, th {
  //     border: none !important
  //   }
  //  }
}

.approver-content {
  .action-bar__action:last-of-type {
    margin-left: 7px;
    margin-right: 0;
  }
}

.approverlist-table {
  border: 1px solid rgba(79, 79, 79, 0.5);
  border-radius: 5px;

  .table {
    display: flex;
    flex-direction: column;
  }

  .approverlist-table-body {
    padding-bottom: 30px;
    background-color: transparent !important;
  }

  .approverlist-table-head {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-bottom: none !important;

    .cell {
      color: #707070;
      text-align: left;
      font-size: 14px;
      font-weight: 700;

      &.actions {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 80px;
      }
    }
    .cw-sort {
      &.current-sorted {
        background: var(--color-code);
      }
      &:before {
        display: none;
      }
      &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f0dc';
        position: absolute;
        margin-left: 5px;
        width: 20px;
        height: 20px;
      }

      &--asc {
        &:after {
          content: '\f0de';
        }
      }

      &--desc {
        &:after {
          content: '\f0dd';
        }
      }
    }
  }

  .approverlist-table-row {
    display: flex;
    border-bottom: 1px solid rgba(79, 79, 79, 0.5);
    position: relative;

    &.loading::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--color-background);
      z-index: 1;
      cursor: not-allowed;
    }
  }

  .badge {
    font-weight: normal;
    padding: 5px 12px;
    min-width: auto;
  }

  @media (min-width: 992px) and (max-width: 1366px) {
    .approverlist-table-row.approverlist-table-head > *,
    .approverlist-table-row > * {
      min-width: 0;
    }
    .badge {
      white-space: initial;
      height: auto;
    }
  }

  .cell {
    flex: 1;
    padding: 10px;
    text-align: left;
    font-family: Open Sans;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    word-break: break-word;

    &.actions {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 80px;
    }
  }

  .cw-sort:before {
    top: 8px;
  }
}

.approverlist-table-row.approver-head,
.approverlist-table-body.approver-body {
  .cell {
    flex: 1;

    &.date {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 140px;
    }
  }
}

.brand-management-table {
  .minimal-width {
    flex: 0 0 220px;
  }
  .inline-actions {
    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: none;
    }
  }
}

.search-input-approver-content {
  &::placeholder {
    font-size: 16px !important;
  }
  font-size: 16px !important;
}

.radio-option-disabled {
  color: var(--color-campaign-disabled-text) !important;
  p {
    color: var(--color-campaign-disabled-text) !important;
  }
}

.vendor-dropdown {
  max-width: 630px;
  margin-left: 30px;
}
.radio-option-disabled {
  color: var(--color-campaign-disabled-text) !important;
  p {
    color: var(--color-campaign-disabled-text) !important;
  }
}
.cw-heading--emaildashboard {
  color: var(--color-primary);
  font-size: var(--font-size-h1);
  font-weight: 400;
  line-height: var(--line-height-h1);
}

.coupon-upload-feat-box {
  .saas-update-title {
    font-size: 15px;
  }
}

.sms-guideline-accordion-arrow {
  padding-left: 10px;
  color: var(--color-black);
}
.react-select__option {
  .react-select-custom-sublabel {
    font-size: 14px;
    color: var(--color-grey-number);
  }

  &--is-selected,
  &:active {
    .react-select-custom-sublabel {
      color: white;
    }
  }
}

.font-text-small {
  font-size: var(--text-small);
}

.cond-required-text {
  margin-top: -24px;
}
.ms-1-25 {
  margin-left: 1.25rem;
}
.footer-disabled {
  background: var(--color-disable-input) !important;
}

.simple-select-option {
  top: 30px;
  right: 0;
}

.loading-text-wrapper {
  margin-top: 10px;
}

@keyframes loading {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}

.loader-text-sp {
  position: relative;
  font-size: 14px;
  color: #fff;
  -webkit-text-stroke: 0.3px #383d52;

  &:before {
    content: attr(data-loading-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: var(--color-primary);
    -webkit-text-stroke: 0px #383d52;
    border-right: 2px solid var(--color-primary);
    overflow: hidden;
    animation: loading 6s linear infinite;
  }
}

.loading-bg {
  background-color: #fff;
  padding: 10px 10px;
  border: 1px solid var(--color-text-grey-2);
  border-radius: 5px;
}

.loader-text {
  font-size: 14px;
  color: var(--color-primary);
}

.cw-banner-design-sections {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 2px solid var(--color-light-grey);
  border-radius: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fff;
}
