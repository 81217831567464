.alert-new-message {
  display: block;
  background-color: var(--color-white);
  border: 1px solid var(--color-highlighter);
  color: var(--color-highlighter);
  border-radius: 10px;
  max-width: 85%;
  margin-left: auto;

  .saas-update-icon {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    align-items: center;

    .icon-span {
      margin-right: 5px;
    }
  }

  .new-update-content {
    flex: 9;
    margin-bottom: 2%;

    p {
      margin: 10px 0;
      font-size: 14px;
      color: var(--color-base);
      text-align: left;
    }
  }
}

.react-select-container {
  //text-transform: capitalize;

  &.error > div,
  &.error:hover > div {
    border-color: var(--color-error);
  }

  .react-select__control {
    &--is-focused {
      border-color: var(--color-primary);
      // box-shadow: 0 0 0 0.2rem var(--color-input-border-highlight);
      box-shadow: none;
    }

    &:hover {
      border-color: var(--color-primary);
    }
  }

  // .react-select__option {
  //   box-shadow: 0 -1px 0 0 #eceeef;
  //   &:first-child {
  //     box-shadow: none;
  //   }
  //   &:hover,
  //   &:active {
  //     background-color: var(--color-primary) !important;
  //     color: white;
  //   }

  //   &--is-selected {
  //     background-color: var(--color-primary) !important;
  //     color: white;
  //   }
  // }

  .react-select__menu {
    box-shadow: none !important;
    border: 1px solid var(--color-primary) !important;
    &:first-child {
      box-shadow: none;
    }
    .react-select__menu-list {
      border-color: var(--color-primary);
      .react-select__option {
        box-shadow: 0 -1px 0 0 #eceeef;

        &:first-child {
          box-shadow: none;
        }

        &:hover,
        &:active {
          background-color: var(--color-primary) !important;
          color: white;
        }

        &--is-selected {
          background-color: var(--color-primary) !important;
          color: white;
        }
      }
    }
  }
}

.email-campaign-brand-selection {
  .react-select__multi-value__remove:hover {
    color: var(--color-base);
    background-color: inherit;
    cursor: pointer;
  }
}
.react-select__option--is-disabled {
  pointer-events: none;
}

.filter-item .react-multi-select__group-heading {
  text-transform: none;
}

.reactselect-invalid {
  .react-select__control {
    border-color: var(--color-error);

    &:hover {
      border-color: var(--color-error);
    }

    &--is-focused {
      border-color: var(--color-error);
      //box-shadow: 0 0 0 0.2rem var(--color-input-border-highlight-invalid);
    }
  }
}

.react-select__menu {
  box-shadow: none !important;
  border: 1px solid var(--color-primary) !important;
  &:first-child {
    box-shadow: none;
  }
  .react-select__menu-list {
    border-color: var(--color-primary);
    .react-select__option {
      //box-shadow: 0 -1px 0 0 #eceeef;

      &:first-child {
        box-shadow: none;
      }

      //&:hover,
      &:active {
        background-color: var(--color-primary) !important;
        color: white;
      }

      &--is-selected {
        background-color: var(--color-primary) !important;
        color: white;
      }
    }
  }
}

.cw--home {
  max-width: var(--container-width);
  margin: 0 auto;
  padding-top: 50px;
  .col-lg-3 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.home-card {
  border: none;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 15px;
  .card-image {
    height: 78px;
    margin: 35px 65px 0;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: 32%;

    @media (max-width: 1024px) {
      height: 350px;
    }

    &.icon-form {
      background-image: url('../../public/campaign-wizard/img/icon_form2.png');
      background-repeat: no-repeat;
      background-size: auto;
    }

    &.icon-sms {
      background-image: url('../../public/campaign-wizard/img/icon_sms2.png');
      background-repeat: no-repeat;
      background-size: auto;
    }

    &.icon-email {
      background-image: url('../../public/campaign-wizard/img/icon_email2.png');
      background-repeat: no-repeat;
      background-size: auto;
    }
    &.icon-wa {
      background-image: url('../../public/campaign-wizard/img/icon_wa2.png');
      background-repeat: no-repeat;
      background-size: auto;
    }

    // &::after {
    //   content: '';
    //   background-image: linear-gradient(to right, #ffb389, #ff0866);
    //   //background-color: #ad19cc;
    //   height: 15px;
    //   width: 100%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }

    .badge {
      position: absolute;
      bottom: 28px;
      right: 0px;
    }
  }

  .card-body {
    &--text {
      height: 130px;
    }

    &--buttons {
      padding: 10px 5px 15px;

      .btn {
        font-size: var(--text-base-size-sm);
      }
      .btn-outline-secondary {
        margin-top: 10px;
      }
    }

    &--title {
      font-weight: bold;
      font-size: var(--font-size-subheading2);
    }

    &--desc {
      font-size: var(--text-base-size-md);
    }
  }
}

.cw-eb-form-section {
  border: 1px solid var(--color-grey);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  .form-section-header {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .form-section-title {
    .form-section-img {
      width: 20px;
      height: 20px;
      display: inline;
      margin-left: 10px;
    }

    .form-section-icon {
      // width: 24px;
      // height: 24px;
      font-size: 20px;
      display: inline;
      margin-left: 10px;
    }

    .section-title {
      display: inline;
      margin-left: 10px;
    }
  }

  .edit-box {
    padding-right: 10px;

    .edit-icon {
      // font-size: 24px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .edit-btn {
      background-color: var(--color-white);
      border: none;
      border-radius: 5px;
      color: var(--color-primary);
      padding: 2px 5px;
      font-size: 18px;
      cursor: pointer;
    }

    .edit-btn:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }

  .status {
    padding-right: 10px;
    //margin-bottom: 5px;
  }

  .status-valid {
    font-size: 24px;
    color: var(--color-completed);
  }

  .status-notvalid {
    font-size: 20px;
    color: var(--color-error);
  }

  .form-section-content {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 20px;
  }

  .form-section-footer {
    padding-bottom: 10px;
  }
}

.form-data-fields {
  .field-row {
    padding: 7px 13px;
    //background-color: #007dbb;
    box-shadow: 0 -1px 0 0 #007dbb;

    &:first-child {
      box-shadow: 0 -1px 0 0 #eceeef;
    }

    &:nth-child(odd) {
      // background-color: #f9f9f9;
      box-shadow: 0 -1px 0 0 #eceeef;
    }

    &:nth-child(even) {
      background-color: #fff;
      box-shadow: 0 -1px 0 0 #eceeef;
    }

    &:last-child {
      box-shadow: 0 1px 0 0 #eceeef, 0 -1px 0 0 #eceeef;
    }

    [class^='col-'] {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &--hide_class_0 {
      box-shadow: none !important;
    }
  }
}

.form-builder-draft {
  display: block;
}

.cw-link-btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  // width: 100%;
}

.cw-formdraft-link {
  display: block;
  text-decoration: none;
  text-align: left;
}

.cw-form-nested {
  position: relative;
  padding-top: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -22px;
    width: 2px;
    height: calc(100% - 9px);
    border-left: 2px dashed var(--color-primary);
  }

  &.template-coupon-inputs {
    &::before {
      top: -5px;
      height: calc(100% + 5px);
    }
  }

  &.email-attributes-inputs {
    &::before {
      top: -20px;
      height: calc(100% + 20px);
    }
  }

  &.know-audience-size-inputs {
    &::before {
      top: -10px;
      height: calc(100% + 10px);
    }
  }
}

.field-array-remove-btn {
  display: inline-block;
}

.cw-formbuilder-code {
  background-color: var(--color-code);
  border: 1px solid var(--color-border);
}

.section-has-error {
  // border-color: var(--color-error);
  border: 1px solid var(--color-error);
  // box-shadow: 0 4px 8px 0 rgba(152, 66, 66, 0.2);
}

.empty-box-40x30 {
  width: 40px;
  height: 30px;
}

.template-modal-wrapper.guideline-wrapper {
  min-height: 0;
  height: 100%;
  .bee-editor-container {
    width: 100%;
    height: calc(100% - 15px);
    padding-top: 0;
    iframe {
      min-width: unset !important;
      background-color: transparent !important;
    }
  }
}

.bee-editor-container {
  width: 100%;
  height: 98vh;
  margin-bottom: 0;
  padding-top: 73px;
}

.no-record-title {
  color: var(--color-error);
}

.template-review {
  display: flex;
  flex-direction: column;
  width: 25%;

  @media (min-width: 1160px) {
    width: 20%;
  }

  .action-bar {
    flex-wrap: wrap;
    gap: 7px;

    .actions-left {
      margin-right: 7px;
    }
  }

  &__image {
    border-radius: 5px;
    border: 0.5px solid var(--color-range-slider-grey);
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }
}

.sms-action-bar {
  display: flex;
  justify-content: center;
  width: 100%;

  .sms-action-btn {
    display: flex;
    align-items: center;
    border: 0.5px solid var(--color-grey-8);
    border-radius: 3px;
    outline: none;
  }
}

.email-template {
  &__card {
    font-family: 'Inter', sans-serif;
    font-size: var(--text-small);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    min-height: 275px;

    &__description {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 15px;
      background: rgba(255, 255, 255, 0.95);
    }

    .card-body {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: -2px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 3px;

      .card-body-contents {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        flex: 0.5;

        .header {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .one-line {
            @media (max-width: 1024px) {
              font-size: var(--text-small);
            }
          }
        }
      }
    }

    .card-error {
      margin-bottom: 3px;
      font-family: 'Inter', sans-serif;
      font-size: var(--text-xsmall);
      line-height: var(--line-height-xxsmall);
      color: var(--color-text-grey-2);
    }

    .updated-at {
      display: flex;
      flex: 0.25;
    }

    &--blank {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 275px;
      .email-template__card__description {
        display: none;
      }

      .card-body {
        display: flex;
      }

      .blank-template-image {
        width: 56px;
        height: 56px;
        margin-top: 16px;
      }
    }

    &--toggle {
      &:hover {
        .email-template__card__description {
          display: none;
        }

        .card-body {
          display: flex;
          background-color: rgba(255, 255, 255, 0.9);

          // background-color: rgba(247, 245, 245, 0.95);
        }
      }

      .card-body {
        display: none;
      }

      .card-text {
        text-transform: uppercase;
      }

      .card-info {
        margin-bottom: 3px;
        font-family: 'Inter', sans-serif;
        font-size: var(--text-xsmall);
        line-height: var(--line-height-xxsmall);
        color: var(--color-grey-number);
      }

      .bold {
        font-weight: 700;
      }
    }

    &--button-container {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-30);
      @media (min-width: 1200px) and (max-width: 1320px) {
        margin-top: 10px;
      }

      .preview-btn {
        color: var(--color-primary);
        margin-top: 10px;
      }
    }
    &--counter {
      color: var(--color-text-grey-1);
      position: absolute;
      bottom: 20px;
      font-weight: 400;
      font-size: var(--text-size-10);
    }

    .card-title {
      color: var(--color-highlighter-email);
      font-weight: bold;
      margin-bottom: 0;
      max-width: 100%;
    }

    .card-text {
      color: var(--color-cancelled);
    }

    .btn {
      border-radius: 5px;
      letter-spacing: 0.25px;
      font-size: var(--text-small);
      white-space: nowrap;

      &.wrap {
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: normal;
        line-height: 1.2;

        @media (max-width: 1300px) {
          max-width: 300px;
        }
      }
    }

    &.no-record {
      flex: auto;
      width: 60%;
      max-width: 100%;
      height: 220px;
      flex-direction: row;
      padding: 50px;

      @media (max-width: 1300px) {
        padding: 20px;
        min-height: 220px;
        height: auto;
      }

      .card-img-left {
        width: 150px;
      }

      .card-body {
        position: relative;
        padding: 0 0 0 1.25rem;
      }

      .card-text {
        color: var(--color-base);
      }

      .card-text:nth-child(2) {
        padding-top: 20px;
      }

      .button-container {
        padding: 30px 0 0;
      }
    }
  }
}

/*Component - EmailTemplateSearch*/
.email-template-search {
  height: 100%;
  background-color: white;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;

  .filter-icon {
    display: none;
  }

  .search-input-wrapper {
    width: 89%;
  }

  .search-button {
    padding: 2px 10px;
  }

  .menu {
    display: none;
  }

  .cw-link-btn {
    margin-top: 15px;
    margin-left: 0;
    white-space: nowrap;
    @media (max-width: 1024px) {
      margin-left: -21px;
    }
  }

  &__info {
    min-height: 30px;
    font-size: var(--text-xsmall);
    margin-bottom: -10px;

    &--text {
      margin-top: 15px;
      margin-left: 10px;
      display: inline-block;
    }
  }

  .circle-singleline {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: var(--text-xsmall);
    line-height: 20px;
    text-align: center;
    background: var(--color-base);
    color: var(--color-white);
    display: inline-block;
    margin-left: 5px;
  }
}

.email-template-filter {
  font-size: var(--text-base-size-sm);

  &__container {
    margin: 12px 0;
  }

  &__header {
    @media (max-width: 1024px) {
      .btn-link {
        padding: 0 5% !important;
      }
      .toggle-icon {
        right: 0px !important;
      }
      .circle-singleline {
        margin-left: -1px;
      }
    }
  }
  &__header .btn-link:focus,
  &__header .btn-link:hover {
    text-decoration: none;
    outline: none !important;
    box-shadow: none;
  }

  .toggle-icon {
    position: absolute;
    right: 10px;
    color: var(--color-base);
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .btn-block {
    text-align: left;
    font-weight: normal;
    color: var(--color-primary);
    font-size: var(--text-small);
    white-space: nowrap;
  }

  .badge-pill {
    min-width: 33px;
  }

  .card-body {
    padding: 0 1.25rem;
  }

  .search-textbox {
    font-size: var(--text-xsmall);
    @media (max-width: 1024px) {
      &::placeholder {
        font-size: 11px !important;
      }
    }
  }

  &__wrapper {
    ul li {
      list-style: none;
    }
  }
}

.email-card {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .email-card-button {
    position: absolute;
    top: 75px;
    min-width: 70%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media screen and (max-width: 1196px) {
      & {
        min-width: 80%;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0px 5%;
      font-size: 14px;
    }
  }

  .email-card-image {
    width: 100%;
    display: block;

    &:hover {
      filter: opacity(25%);
    }
  }
  .email-card-raw-image {
    width: 100%;
    display: block;
  }
}

.email-card--blank {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  &:hover {
    .preview-not-availabe {
      display: none;
    }
  }
}

.select-template-page {
  background-color: var(--color-grey-2);
  padding: 15px;
  border-radius: 5px;
  display: flex;

  .col-md-9 {
    padding-right: 0;
  }
}

.email-template-list {
  position: relative;
  min-height: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.select-template-page > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  div {
    display: flex;
  }

  &__action {
    background: white;
    width: 30px;
    height: 29px;
    margin-left: 7px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #9d9d9d;
    border-radius: 3px;
    outline: none;

    &:hover {
      border-color: var(--color-primary);
    }

    &:first-of-type {
      margin-left: 0;
    }

    img {
      max-width: 25px;
    }
  }
}

.template-preview-overlay {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .close-icon {
    float: unset;
    display: flex;
    justify-content: flex-end;
    width: 106%;
    height: 26px;
  }

  .template-title {
    font-size: var(--text-small);
    display: flex;
    justify-content: space-between;
  }

  .campaign {
    font-size: var(--text-small);
    font-weight: 700;
  }

  .template-preview-box {
    width: 400px;
    overflow-y: auto;

    .preview-text {
      font-size: var(--text-base-size-sm);
      color: var(--color-text-grey-1);
      font-weight: 700;
    }
  }

  .template-category-text {
    color: var(--color-text-grey-1);
    text-transform: uppercase;
  }

  .template-info-text {
    color: var(--color-text-grey-1);
    font-size: var(--text-small);
  }

  .template-meta-info {
    color: var(--color-text-grey-1);
    font-size: 12px;
  }

  .template-preview-image {
    width: 100%;
  }
}

// edit template page
.page-edit-template {
  padding: 0;

  &_wrapper {
    padding: 0 15px;
  }
}

// Toolbar
.toolbar {
  border-top: 8px solid var(--color-dark);
  border-bottom: 1px solid var(--color-grey);
  padding: 10px 15px;
  position: fixed;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  z-index: 999;
  top: 0;
  display: flex;

  &__center {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-right: -5px;
  }

  &__right {
    text-align: right;
    width: 406px;

    @media screen and (max-width: 1060px) {
      width: 334px !important;
    }

    @media screen and (max-width: 1210px) {
      width: 346px;
    }

    .action-bar {
      margin-top: 13px;
      margin-bottom: 0;
    }
  }

  .logo {
    max-width: 37px;
    max-height: 40px;
    margin-right: 20px;
  }

  .button-icon {
    &.grid-icon {
      width: 28px;
    }
  }

  &__nav-button {
    margin-top: 10px;
    padding: 5px 20px;
    line-height: normal;
  }

  &__nav-icon {
    margin-top: 10px;
    line-height: 1;
    padding: 2px;
  }

  &__nav-saving-status {
    color: var(--color-completed);
    font-size: var(--text-xsmall);
    padding-top: 16px;
    display: inline-block;
    float: right;
  }

  .icon-color-primary {
    color: var(--color-primary);
  }
}

.form-builder-container {
  .form-control {
    &:focus {
      border-color: var(--color-primary);
      box-shadow: none;
    }
    &::placeholder {
      font-size: 14px;
      color: var(--color-text-grey-2);
    }
  }
  .is-invalid {
    &:hover {
      border-color: var(--color-error);
    }

    &:focus {
      border-color: var(--color-error);
    }
  }
}

.select-email-content-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  //padding: 3px 0px 0px 3px;
  padding: 10px;
  margin-right: 17px;
  width: 168px;
  height: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.selected,
  &:hover {
    border-width: 1px;
    border-color: #007dbb;
  }

  .icon-img {
    width: 20px;
    height: 20px;
  }

  .radio-input-wrapper {
    width: 100%;
  }

  .email-content-label-text {
    font-size: 14px;
    margin-top: 10px;
  }

  [type='radio'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.select-email-content-label {
  display: flex !important;
  font-size: 14px;
}

.email-type-desc-font {
  font-size: 14px;
  color: var(--color-grey-number);
}

.send-after-field-text {
  font-weight: bold;
}

.send-follow-up-container {
  margin-top: 30px;
}

.bee-editor-footer {
  display: none;
}

.cw-unstyle-btn {
  background: none !important;
  border: none;
  cursor: pointer;
}

.cw-unstyle-padding {
  padding: 0 !important;
}

.cw-card-checkbox {
  .card-div {
    width: 168px;
    height: 110px;
    margin-right: 13px;
    float: left;
    box-sizing: border-box;
    border: 2px solid var(--color-range-slider-grey);
    border-radius: 5px;
    cursor: pointer;
  }

  .outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .card-div input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    margin-left: 0.7rem;
    margin-top: 0.7rem;
    width: 15px;
    height: 15px;
    opacity: 0;

    &:checked {
      opacity: 1;
      left: unset;
      accent-color: var(--color-primary);
    }
  }

  .card-div:hover {
    border: 2px solid var(--color-primary);
    border-radius: 5px;
  }

  // .card-div input[type=checkbox]:checked {
  //     opacity: 1;
  // }

  .selected-box {
    border: 2px solid var(--color-primary);
  }

  input[type='checkbox']:checked ~ div {
    //border: 2px solid var(--color-primary);
    // border-radius: 5px;
  }

  .main {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    width: 95px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .card-checkbox-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 11px;
  }
}

.temp-box-push {
  margin-bottom: 24px;
}

.cw-card-radio {
  margin-bottom: 10px;
  .card-div {
    width: 168px;
    height: 110px;
    margin-right: 13px;
    float: left;
    box-sizing: border-box;
    border: 2px solid var(--color-range-slider-grey);
    border-radius: 5px;
    cursor: pointer;
  }

  .outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .card-div input[type='radio'] {
    position: absolute;
    cursor: pointer;
    margin-left: 0.7rem;
    margin-top: 0.7rem;
    width: 15px;
    height: 15px;
    opacity: 0;

    &:checked {
      opacity: 1;
      left: unset;
      accent-color: var(--color-primary);
    }
  }

  .card-div:hover {
    border: 2px solid var(--color-primary);
    border-radius: 5px;
  }

  // .card-div input[type=checkbox]:checked {
  //     opacity: 1;
  // }

  .selected-box {
    border: 2px solid var(--color-primary);
  }

  input[type='radio']:checked ~ div {
    //border: 2px solid var(--color-primary);
    // border-radius: 5px;
  }

  .main {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    width: 95px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .card-radio-content {
    text-align: center;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .card-radio-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 11px;
  }
}

.cross-link {
  margin-top: 18px;
  // flex: 2;
  font-size: var(--text-small);

  .template-btn {
    //margin-left: 4px;
  }

  @media (max-width: 992px) {
    // flex: 1;
  }
}

.flyoutmodal {
  &__container {
    display: inline-block;
    position: relative;
    margin-left: 7px;
  }

  &__box {
    position: absolute;
    width: 600px;
    //height: 366px;
    border: 1px solid #007dbb;
    border-radius: 10px;
    background: #ffffff;
    top: 58px;
    left: 16px;
    padding: 25px 25px;
    max-height: 85vh;
    overflow-y: auto;
  }

  &__up-arrow {
    position: absolute;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-left: 1px solid #007dbb;
    border-top: 1px solid #007dbb;
    background-color: #ffffff;
    rotate: 45deg;
    left: 32px;
    top: 48px;
  }
}

.email-design-checklist-nested {
  list-style: disc;
  //color: var(--color-primary);
  margin-top: 20px;
  &__item {
    font-size: var(--text-small);
    .item-title {
      color: var(--color-primary);
      font-weight: bold;
    }
    &::marker {
      color: var(--color-primary);
    }
  }

  .li-item-color::before {
    color: var(--color-primary);
  }
}

.charcountinput {
  &__count {
    right: 5px;
    bottom: 7px;
  }

  &__count-wa {
    right: 22px;
    bottom: 7px;
  }

  &__helptext {
    font-size: 12px !important;
    color: var(--color-grey-number);
    text-align: right;
  }
}

.charcount-textarea {
  &__count {
    right: 5px;
    bottom: 7px;
    font-size: 14px;
  }

  &__helptext {
    font-size: 12px !important;
    color: var(--color-grey-number);
    //text-align: right;
    margin-top: 5px;
  }

  &__helparea {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__error-mesg {
    margin-top: 5px;
  }
}

.save-subject-preheader-info {
  font-size: 14px;
}

.subject-preheader-form {
  &__info {
    font-size: 14px;
  }
  &__check-icon {
    color: var(--color-completed);
  }
  &__warning-icon {
    color: var(--color-error);
  }
}

.flyout-content {
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 15px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.size-buttons {
  display: flex;
  border: 1px solid #707070;
  border-radius: 2px;

  .value {
    width: 45px;
    background: #f7f5f5;
    border: 1px solid #707070;
    border-top-width: 0px;
    border-bottom-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.counter-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__image {
    width: 10px;
    height: 10px;
  }
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
}

.emoji-count-input {
  &__count {
    right: 48px;
    bottom: 9px;
    font-size: 14px;
  }

  &.is-invalid {
    .react-input-emoji--input {
      margin: 0px 0px;
      border: 1px solid var(--color-error) !important;
      &:focus {
        border: 1px solid var(--color-error) !important;
      }
    }
  }

  &__helptext {
    font-size: 12px !important;
    color: var(--color-grey-number);
    //text-align: right;
    margin-top: 5px;
  }

  .react-input-emoji--container {
    margin: 0px 0px;
    // &:focus {
    //   border: 1px solid var(--color-primary) !important;
    // }
  }

  .react-input-emoji--input {
    margin: 0px 0px !important;
    border-radius: 0.25rem;
    &:focus {
      border: 1px solid var(--color-primary);
    }
  }

  &__helparea {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__error-mesg {
    margin-top: 5px;
  }

  .react-emoji-picker--wrapper {
    position: absolute;
    top: 45px;
    right: 49px;
    height: 357px;
    width: 348px;
    overflow: hidden;
    z-index: 10;
  }

  #emoji-mart-search-25 {
    display: none;
  }

  .emoji-mart-search {
    display: none;
  }

  .react-input-emoji--input {
    padding: 9px 48px 11px 12px;
  }
}

.form-label-section {
  font-size: 16px;
  color: var(--color-primary);
  font-weight: 400;
}

.email-submit-page-ul {
  margin-left: 20px;
  li {
    margin-bottom: 10px;
  }
}

.emoji-custom-input {
  &__count {
    right: 54px;
    bottom: 8px;
    z-index: 99;
    font-size: 14px;
  }

  &__helptext {
    font-size: 12px !important;
    color: var(--color-grey-number);
    //text-align: right;
    margin-top: 5px;
  }

  &__input {
    padding-right: 54px;
    &.is-invalid {
      padding-right: 54px !important;
    }
  }

  &__helparea {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__error-mesg {
    margin-top: 5px;
  }

  &__picker {
    position: absolute;
    top: 74px;
    left: 2px;
    z-index: 999;
    border: 1px solid var(--color-primary);
  }

  &__textlink {
    font-size: 12px;
    margin-top: 5px;
    text-decoration: none;
  }
}

.enhanced-textarea-sms {
  .react-mentions-sms__input {
    min-height: 86px;
    padding-right: 0.75rem !important;
    border: none;
  }
  .emoji-custom-input {
    &__textlink {
      font-size: 14px;
      margin-top: 0;
    }
  }
}

.enhanced-textarea-wa {
  .react-mentions-wa__input {
    min-height: 86px;
    padding-right: 0.75rem !important;
    border: none;
  }
  .emoji-custom-input {
    &__textlink {
      font-size: 14px;
      margin-top: 0;
    }
  }
}

.email-dashboard-checkbox {
  display: flex;
  align-items: center;
  //margin-left: 10px;
  //margin-right: 55px;
  &__label {
    font-size: 14px;
  }

  // @media (max-width: 1420px){
  //   margin-right: 45px;
  // }
}

.send-test-email {
  &__icon {
    width: 60px;
    height: 50px;
  }
  &__max-attempt {
    color: var(--color-error) !important;
  }
  &__title {
    margin-top: 5px;
  }

  &__emails {
    font-size: 14px;
  }

  &__attpm-text {
    font-size: 14px;
    line-height: 23px;
  }
}

.email-dashboard-slider {
  .icon {
    cursor: pointer;
    padding: 5px;
    background-color: white;
    border: 0.5px solid #b9b9b9;

    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right-width: 0;
    }
    &:last-of-type {
      border-left-width: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  .selected {
    background-color: var(--color-primary);
    svg path {
      fill: white;
    }
  }

  svg path {
    fill: var(--color-primary);
  }
  .switch-saas {
    margin-bottom: 0px;
  }
  .dark-text {
    margin-bottom: 0px;
  }
}

.table-striped-dashboard {
  .preview-icon {
    width: 22px;
    height: 22px;
  }
}

.email-custom-select {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  min-width: 185px;

  .title {
    font-size: 14px;
    padding: 5px;
    padding-right: 0;
  }

  .down-arrow {
    margin-left: 5px;
    margin-right: 5px;
  }

  .option {
    font-size: 14px;
    width: 180px;
    padding: 5px;
    padding-right: 22px;
    text-align: right;

    &:hover {
      color: white;
      background-color: var(--color-primary);
    }
  }

  .options {
    box-shadow: 4px 6px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background-color: white;
    z-index: 15;
    position: absolute;
    width: max-content;
  }
}

.react-multi-select-container {
  //text-transform: capitalize;
  .custom-tag-heading {
    color: #007dbb;
    display: block;
    font-size: 12px;
    width: 100%;
    // height: 10px;
    line-height: 0.7;
  }

  .custom-tag-value {
    display: block;
    font-size: 12px;
    padding-bottom: 3px;
    //position: relative;
    //top: -15px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 93px;
    }
  }

  &.error > div,
  &.error:hover > div {
    border-color: var(--color-error);
  }

  .react-multi-select__control {
    &--is-focused {
      border-color: var(--color-primary);
      // box-shadow: 0 0 0 0.2rem var(--color-input-border-highlight);
      box-shadow: none;
    }

    &:hover {
      border-color: var(--color-primary);
    }
  }

  // .react-select__option {
  //   box-shadow: 0 -1px 0 0 #eceeef;
  //   &:first-child {
  //     box-shadow: none;
  //   }
  //   &:hover,
  //   &:active {
  //     background-color: var(--color-primary) !important;
  //     color: white;
  //   }

  //   &--is-selected {
  //     background-color: var(--color-primary) !important;
  //     color: white;
  //   }
  // }

  .react-multi-select__indicators {
    height: 38px;
  }
  .react-multi-select__loading-indicator {
    position: absolute;
    right: 20px;
  }
  .react-multi-select__control {
    height: 38px;
    min-height: unset !important;
    max-height: 38px;

    &:hover {
      border-color: var(--color-primary);
    }
  }
  .react-multi-select__menu {
    box-shadow: none !important;
    border: 1px solid var(--color-primary) !important;
    &:first-child {
      box-shadow: none;
    }
    .react-multi-select__menu-list {
      border-color: var(--color-primary);
      .react-multi-select__option {
        box-shadow: 0 -1px 0 0 #eceeef;

        &:first-child {
          box-shadow: none;
        }

        &:hover,
        &:active {
          background-color: var(--color-primary) !important;
          color: white;
        }

        &--is-selected {
          background-color: white !important;
          color: #000;
        }
      }
    }
  }
}

.react-multi-select__menu {
  box-shadow: none !important;
  border: 1px solid var(--color-primary) !important;
  &:first-child {
    box-shadow: none;
  }
  .react-multi-select__menu-list {
    border-color: var(--color-primary);
    .react-multi-select__option {
      //box-shadow: 0 -1px 0 0 #eceeef;

      &:first-child {
        box-shadow: none;
      }

      //&:hover,
      &:active {
        background-color: var(--color-primary) !important;
        color: white;
      }

      &--is-selected {
        background-color: white !important;
        color: #000;
      }
    }
  }
}

[id='optimizeSendTime']:checked + label,
[id='optimizeSendTime']:not(:checked) + label {
  padding-left: 75px !important;
}

[id='optimizeSendTime']:checked + label,
[id='optimizeSendTime']:not(:checked) + label {
  &::before {
    background: url('../images/bulb.svg');
    background-size: 60px 60px;
    background-repeat: no-repeat;
    border: none !important;
    height: 60px;
    width: 60px;
  }
  &::after {
    display: none !important;
  }
}

.email-template-page-padding {
  padding-top: 73px;
}

.email-overlay-wrapper {
  margin: 0 2%;
  position: relative;

  .react-select__multi-value__remove:hover {
    color: var(--color-base);
    background-color: inherit;
    cursor: pointer;
  }
}
.email-overlay-close-modal {
  position: absolute;
  right: -3px;
  top: -28px;
}
.image-guideline .email-overlay-close-modal {
  top: 0;
}

.email-title-close {
  position: absolute;
  right: -3px;
}

.email-save-model-wrapper {
  margin: 0 2%;
  position: relative;
}

.email-overlay-header-box {
  width: 60px;
  min-width: 60px;
  height: 50px;
  min-height: 50px;
  border-radius: 5px;
  background: linear-gradient(
      180deg,
      #da3eb8 35.42%,
      #da3eb8 39.58%,
      rgba(218, 62, 184, 0) 100%
    ),
    linear-gradient(180deg, #ff0f7d 99.99%, rgba(255, 15, 125, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: #fff;
  }
  &__icon {
    color: #fff;
  }
}

.cw-modal--coupon-guidelines {
  max-width: 900px;
  width: 900px;
  min-height: 475px;
  overflow: auto !important;

  .email-overlay-header-box {
    background: transparent;
  }

  .email-overlay-close-modal {
    top: 0;
    right: 0;
  }

  .overlay-content-wrapper {
    position: relative;
    height: 400px;

    .overlay-content {
      padding-right: var(--space-20);
      max-height: 80%;
      overflow: auto;
    }

    .bottom-cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-right: var(--space-20);
    }
  }
}

.email-overlay-scroll {
  //height: 60vh;
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 14px;
  overflow-x: hidden;
  padding-right: 10px;

  .context-radio-buttons {
    gap: 15px;
  }
}

.cross-cover {
  height: 50px;
  width: 50px;
  background-color: white;
  position: absolute;
  right: 0;
}

.email-badge-green {
  color: #fff;
  background-color: var(--color-green-pill);
}

.input-clear {
  position: relative;
  width: 100%;
  &__cross {
    position: absolute;
    right: 6px;
    bottom: 7px;
    z-index: 99;
    font-size: 14px;
    cursor: pointer;
  }
}
.email-collection-row-card {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .email-card-image {
    width: 100%;
    display: block;

    &:hover {
      filter: opacity(25%);
    }
  }
  .email-card-raw-image {
    width: 100%;
    display: block;
  }
}
.email-card__overflow-menu {
  background-color: #e2e2e2;
}

.email-card__overflow-menu-item {
  margin-left: auto;
  display: block;
  color: var(--color-base) !important;
  .fa-trash {
    font-size: 10px;
  }
}

.email-alert-link-btn {
  font-weight: bold;
  text-decoration: underline;
}

.alert-close-btn {
  top: 22px !important;
}

.action-bar-image {
  padding: 3px;
  min-width: 28px;
}
.checklist-btn {
  margin-top: 11px;
  width: 33px;
  height: 31px;
}

.email-brief-history {
  .history-col-heading {
    height: unset;
    width: unset;
    white-space: unset;
  }
}

.thumbnail {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  min-height: 200px;
}

.continue-section-empty {
  flex: 1 1 0%;
  margin-right: 40px;
}

.continue-camp-btn-container {
  display: flex;
  justify-content: space-around;
  width: 20%;
}

.SMS-continue-camp-btn-container {
  display: flex;
  justify-content: flex-end;
}

.sms-overview-tables {
  .table-lable {
    padding-right: 25px;
  }
}

.resume-email-camp {
  @media (max-width: 1290px) {
    padding: 0 10px !important;
  }
}
.unordered-list-item::before {
  content: '' !important;
}

.unordered-list-item::marker {
  color: var(--color-primary);
}

.guideline-heading {
  color: var(--color-primary);
}

.email-d {
  height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tabpanel {
  overflow-y: auto;
}
.cw-report-dashboard-wrapper {
  .tabpanel {
    overflow-y: unset;
  }
}
.email-template-search-wrapper {
  min-width: 0;
  transition: flex 0.15s, margin 0.15s;
}

.toggle-button {
  position: absolute;
  background-color: var(--color-toggle-button);
  border-radius: 50%;
  z-index: 10;
  text-align: center;
  border: none;
  vertical-align: middle;
  height: 30px;
  width: 30px;
  left: -25px;
  top: 50px;
  transition: outline 0.1s;

  &:hover {
    outline: 3px solid var(--color-primary);
  }

  svg {
    vertical-align: unset;
    transition: transform 0.5s;

    &.rotated {
      transform: rotate(180deg);
    }

    path {
      fill: var(--color-white);
    }
  }
}

.template-filter-visible {
  flex: 1;
  margin-left: 20px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.template-filter-hidden {
  flex: 0;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.react-mentions-email,
.react-mentions-sms {
  //@extend .form-control;
  &.is-invalid {
    .react-mentions-email__input,
    .react-mentions-sms__input {
      //@extend .form-control
      border-color: var(--color-error) !important;
    }
  }
}

.cw-overview {
  .email-card-image:hover {
    filter: opacity(100%);
  }
}
.email-dash-notification-link {
  font-weight: bold;
  text-decoration: underline;
}
.abtest-icon-image {
  width: 17px;
  height: 20px;
  margin-right: 4px;
}

.winning-variant-text {
  color: #ff0f7d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.winning-variant-wrapper {
  display: flex;
  flex-direction: column;
  width: 20%;
  .template-review {
    width: 100%;
  }
}

.winning-variant-note {
  color: var(--color-text-grey-3);
  font-size: 14px;
}

.email-secondary-theme {
  .themes-container {
    gap: var(--space-10);
  }

  .description {
    color: var(--color-grey-number);
    font-size: var(--text-base-size-sm);
  }

  .theme-item {
    position: relative;
    width: 170px;
    height: 55px;
    padding: var(--space-10) var(--space-10) var(--space-10) var(--space-25);
    border: 2px solid var(--color-range-slider-grey);
    border-radius: var(--space-10);
    gap: var(--space-15);
    cursor: pointer;

    &.selected {
      border-color: var(--color-primary);
    }

    &:hover {
      border-color: var(--color-primary);
    }

    [type='checkbox']:checked {
      left: var(--space-10);
      top: var(--space-10);
      accent-color: var(--color-primary);
    }

    [type='radio']:checked {
      left: var(--space-10);
      top: var(--space-10);
      accent-color: var(--color-primary);
    }

    .label-text {
      gap: var(--space-15);
      cursor: pointer;

      .theme-icon {
        width: var(--text-base-icon-size-md);
        height: var(--text-base-icon-size-md);
      }

      .theme-label {
        font-size: var(--text-xsmall);
        word-break: break-word;
      }

      .content-theme-icon {
        width: 20px;
        height: 20px;
      }

      .content-theme-icon-alt {
        width: 25px;
        height: 25px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      .label-text {
        cursor: not-allowed;
      }
      &:hover {
        border-color: var(--color-range-slider-grey);
      }
      &.selected:hover {
        border-color: var(--color-primary);
      }
    }
  }

  .theme-item-wide {
    width: 200px;
  }
}
.release-note-bar {
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  .release-note-image {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: contain;

    &.icon-form {
      background-image: url('../../public/campaign-wizard/img/icon_form.png');
    }

    &.icon-sms {
      background-image: url('../../public/campaign-wizard/img/icon_sms.png');
    }

    &.icon-email {
      background-image: url('../../public/campaign-wizard/img/icon_email.png');
    }
  }

  .release-note-category {
    color: #9a33a0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .release-note-status {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    &.live {
      color: #5cb85c;
    }
    &.comingSoon {
      color: #ffc61a;
    }
  }
}

.image-select-container {
  overflow: hidden;
}

// template tests modal
.ui.modal.template-tests-modal {
  max-height: 90vh;

  @include media-breakpoint-up(xl) {
    width: 750px;
  }

  .header {
    padding-bottom: 0;
  }
  .close {
    top: 30px;
    right: 10px;
  }
  .content {
    padding: 0 60px;
    padding-right: 25px;

    .description {
      max-height: 370px;
      overflow: auto;

      @media (max-height: 550px) {
        max-height: 300px;
      }
      @media (max-height: 460px) {
        max-height: 200px;
      }
    }

    p {
      font-size: var(--text-base-size);
    }
  }

  &.initialize {
    .content {
      padding-left: 85px;
    }
  }

  .test-item,
  .success-item,
  .error-items {
    img {
      width: 21px;
      height: 21px;
    }
  }

  .test-items {
    .test-item-title {
      line-height: 1;
    }
  }

  .template-tests {
    padding-right: 25px;
    &-note {
      font-size: var(--text-small);
    }
  }
  .actions-link {
    font-size: var(--text-small);
  }

  .success-items {
    p {
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--text-base-size);
    }
    .heading {
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--text-base-size);
    }
    i.icon {
      color: var(--color-green);
      font-size: var(--font-size-h3);
    }
  }

  .error-items {
    .heading {
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--text-base-size);
    }
    .error-item {
      padding: var(--space-15) var(--space-25) var(--space-15) var(--space-5);
      border: 1px solid var(--color-error);
      border-radius: var(--space-5);
      &.success {
        border: 1px solid var(--color-completed);
      }
      &.warning {
        border: 1px solid var(--color-processing);
      }

      .tooltip-icon {
        color: var(--color-primary);
        cursor: pointer;
      }

      a {
        word-break: break-all;
      }
      p {
        font-size: var(--text-small);
      }
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        max-width: 60px;
        min-height: 40px;
        margin-right: var(--space-15);
        border: 1px solid var(--color-background-btn-secondary);
        border-radius: var(--space-5);

        &.preview {
          border: none;
          border-radius: 0;
          background-color: var(--color-background-btn-secondary);

          .error-image {
            max-width: 100%;
            width: auto;
            height: auto;
          }
        }

        &.failed-image {
          background-color: transparent;
          border: 1px solid var(--color-background-btn-secondary);
          border-radius: var(--space-5);
          max-height: 40px;
        }

        .error-image {
          width: 24px;
          height: auto;
        }
      }
      .warning-icon {
        font-size: 22px;
        color: var(--color-processing);
      }
    }
    p.title {
      font-size: var(--text-small);
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0.25rem;
    }
    i.icon {
      color: var(--color-error);
      font-size: var(--font-size-h3);
    }

    .check {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
.ui.modal {
  & > .actions {
    padding: 15px 30px;
  }
}
.runtest {
  &--init {
    margin-top: 90px;
  }
  &--initmsg {
    min-height: 20px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 80px;
    font-size: var(--font-size-h3);
  }
  &--init-msg1 {
    -webkit-animation: initMsgHide 2s forwards;
    animation: initMsgHide 2s forwards;
  }
  &--init-msg2 {
    -webkit-animation: initMsgShow 2.1s forwards;
    animation: initMsgShow 2.1s forwards;
    position: absolute;
    top: 0;
    left: 15%;
  }
  &--loader {
    width: 4rem;
    height: 4rem;
  }
  &--error {
    position: relative;
    min-height: 300px;
    padding-right: 25px;
  }
  &--error-icon {
    width: 100%;
    height: 62px;
  }
  &--error-splash {
    position: absolute;
    left: -15px;
    top: 0;
    width: 100%;
    -webkit-animation: initMsgHide 0.5s forwards;
    animation: initMsgHide 0.5s forwards;
  }
  &--error-list {
    -webkit-animation: initMsgShow 0.5s forwards;
    animation: initMsgShow 0.5s forwards;
  }
  &--success {
    position: relative;
    min-height: 300px;
  }
  &--success-splash {
    position: absolute;
    left: -15px;
    top: 0;
    width: 100%;
    -webkit-animation: initMsgHide 2s forwards;
    animation: initMsgHide 2s forwards;
  }
  &--success-list {
    -webkit-animation: initMsgShow 2s forwards;
    animation: initMsgShow 2s forwards;
    padding-right: 25px;
  }
}
@keyframes initMsgHide {
  0% {
    opacity: 1;
    display: block;
  }
  90% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes initMsgHide {
  0% {
    opacity: 1;
    display: block;
  }
  90% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes initMsgShow {
  0% {
    opacity: 0;
    display: none;
  }
  90% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: inherit;
  }
}
@-webkit-keyframes initMsgShow {
  0% {
    opacity: 0;
    display: none;
  }
  90% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: inherit;
  }
}

.dashboard-toggle-wrapper {
  display: flex;
  flex: 1;
  align-items: center;

  .email-custom-select {
    .title {
      white-space: nowrap;
    }
  }

  .email-dashboard-checkbox {
    label {
      white-space: nowrap;
    }
  }
}

.flex-one {
  flex: 1;
}

.email-template-error {
  margin-top: 0px;
  margin-bottom: 10px;
  a {
    color: var(--color-error);
    text-decoration: underline;
  }
}

.check-with-tooltip {
  label {
    flex-direction: column;

    .tooltip-container {
      .icon {
        cursor: pointer;
        color: var(--color-black);
      }
    }
  }
}

.email-template-error {
  margin-top: 0px;
  margin-bottom: 10px;
  a {
    color: var(--color-error);
    text-decoration: underline;
  }
}

.coupon-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--color-grey-2);
  height: 105px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  .drop-help-text {
    text-align: center;
    .main-text {
      margin-top: 8px;
      font-size: 14px;

      span {
        font-size: var(--font-size-subheading);
        animation: flicker 1s linear infinite alternate;
      }
    }
    .sub-text {
      font-size: 12px;
    }
    .file-upload-icon {
      background-color: var(--color-primary);
      color: #fff;
      border-radius: 50%;
      padding: 4px 7px;
      // width: 24px;
    }
  }

  &.loading {
    background: linear-gradient(
          90deg,
          var(--color-text-grey-1) 50%,
          transparent 0
        )
        repeat-x,
      linear-gradient(90deg, var(--color-text-grey-1) 50%, transparent 0)
        repeat-x,
      linear-gradient(0deg, var(--color-text-grey-1) 50%, transparent 0)
        repeat-y,
      linear-gradient(0deg, var(--color-text-grey-1) 50%, transparent 0)
        repeat-y;
    background-color: var(--color-border-table);
    background-size: 6px 1px, 6px 1px, 1px 6px, 1px 6px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    border: none;
    animation: linearGradientMove 0.2s infinite linear;
  }

  &.isError {
    border: 2px dashed var(--color-error);
  }
}

.file-upload-zone-wrap {
  margin-bottom: 20px;
}

.success-text {
  color: var(--color-green);
}

.select-email-label img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.live-labels-modal {
  .labels-wrapper {
    max-height: 90vh;
    overflow: auto;
    padding-right: 1rem;
    max-height: 350px;

    @media (max-height: 590px) {
      max-height: 250px;
    }
    @media (max-height: 500px) {
      max-height: 200px;
    }
    @media (max-height: 430px) {
      max-height: 150px;
    }
  }
  .label-value {
    font-size: var(--text-base-size-sm);
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes linearGradientMove {
  100% {
    background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
  }
}
.alert-qna--info {
  a {
    text-decoration: underline;
  }
  .external-link-wrapper {
    display: inline-block;
    .external-link-icon {
      margin-bottom: 2px;
      margin-left: 2px;
    }
  }
}

.info-grey {
  &--small {
    color: var(--color-grey-number);
    ul li,
    a,
    p:last-child {
      font-size: var(--text-small);
    }
    p {
      color: var(--color-base);
    }
    ul {
      margin-left: 15px;
    }
  }
}
@keyframes flicker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.datetime-width {
  width: 250px;
}

.row-col-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.opt-out-combined {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  .opt-out-box {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    padding-left: 0.75rem;
    padding-bottom: 0.375rem;
    //color: rgb(73, 80, 87);
    color: var(--color-grey-number);
    cursor: default;
    z-index: 10;
  }

  &.is-invalid {
    border: 1px solid var(--color-error);
  }
}

.downloadLinkContainer {
  position: relative;
  word-wrap: break-word;
}

.dw-loader {
  top: 0;
  left: 0;
  position: absolute;
  background-color: #ffffff;
  //background-color: rgba(157, 157, 157, 0.6);
  // background-color: rgba(255, 255, 255, 0.4);
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
  //  color: #007dbb;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
}

.alert-link-loader {
  color: #856404;
  background-color: #fff3cd;
}

.dw-loader-black {
  color: #000;
}

.email-validation-btn {
  position: relative;
  z-index: 0;
  color: #fff;
}

.email-validation-btn::after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  border-radius: 10px;
}
/* glow */
.email-validation-btn::before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing-test-btn 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 1;
}

@keyframes glowing-test-btn {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
