.skypink {
  --font-primary: 'Open Sans', sans-serif;

  /* set base values */
  --text-base-size: 16px;
  --text-base-line-height: 24px;

  /* spacing */
  --space-5: 5px;
  --space-13: 13px;
  --space-15: 15px;
  --space-10: 10px;
  --space-20: 20px;
  --space-23: 23px;
  --space-24: 24px;
  --space-25: 25px;
  --space-30: 30px;
  --space-33: 33px;
  --space-40: 40px;
  --space-43: 43px;
  --space-50: 50px;
  --space-60: 60px;
  --space-100: 100px;

  /* colors */
  --color-base: #3c373a;
  --color-primary: #007dbb;
  --color-secondary: #043e5d;
  --color-heading: #232e83;
  --color-grey: #cccccc;
  --color-table: #f9f9f9;
  --color-error: #df1642;
  --color-processing: #f0ad4e;
  --color-draft: #5bc0de;
  --color-cancelled: #818a91;
  --color-completed: #5cb85c;
  --color-border: #dfe2e7;
  --color-border-btn: #043e5d;
  --color-search-border: #e7eaeb;
  --color-background-btn-secondary: #e9e9e9;
  --color-highlighter: #9a33a0;
  --color-highlighter-email: #ff0f7d;
  --color-input-border: #80bdff;
  --color-input-border-highlight: #007bff40;
  --color-input-border-highlight-invalid: #dc354540;
  --color-white: #fff;
  --color-black: #000;
  --color-email-badge-info: #ffc000;

  /* typography */
  --font-size-h1: 36px;
  --font-size-h2: 24px;
  --font-size-h3: 18px;
  --font-size-xsmall: 11px;
  --font-size-subheading: 20px;
  --font-size-email-h1: 32px;
  --font-size-subheading2: 22px;

  --line-height-h1: 49px;
  --line-height-h2: 33px;
  --line-height-h3: 24px;

  --text-small: 14px;
  --text-xsmall: 12px;
  --text-size-10: 10px;

  --line-height-small: 19px;
  --line-height-xsmall: 12px;

  /* border colors */
  --color-border-table: #eceeef;

  /* Container */
  --container-width: 1140px;
}
