.overflow-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: right;

  & > .inline-button {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;

    img {
      height: 13px;
    }
  }

  .overflow-menu__list {
    position: absolute;
    top: 33px;
    left: 30px;
    right: 8px;
    background: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    &__item {
      font-family: 'Inter', sans-serif;
      font-size: var(--text-small);
      line-height: 17px;
      color: var(--color-base);
      text-align: left;
      padding: 5px 12px;

      &:hover {
        background-color: lightgray;
      }
    }
  }
}

.email-highlight {
  color: var(--color-highlighter-email) !important;
}

.email-summary-card {
  background: rgba(247, 245, 245, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 16px;
  padding-top: 34px;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &__image-container {
    position: absolute;
    top: -20px;
    width: 47px;
    height: 42px;
    background: #b9b9b9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: var(--space-20);
      height: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    .icon-text {
      font-size: 13px;
      margin-top: 5px;
    }
  }

  &__title {
    font-family: 'Open Sans';
    font-size: var(--text-large);
    line-height: var(--line-height-small);
    color: var(--color-primary);
    margin-bottom: 8px;

    &.black {
      color: var(--color-black);
    }
  }

  &__heading {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: var(--text-base-size);
    line-height: var(--line-height-large);

    color: var(--color-highlighter-email);
  }

  &__text {
    font-family: 'Open Sans';
    font-size: var(--text-base-size-sm);
    line-height: var(--line-height-small);
    color: var(--color-black);
  }

  &__bold {
    text-transform: uppercase;
    font-size: var(--text-base-size-sm);
    color: var(--color-grey-number);
    font-weight: bold;
  }

  .consumer-count-container {
    .consumer-count {
      font-size: var(--font-size-h3);
      font-weight: 600;
    }
    .icon {
      font-size: var(--text-base-size);
      cursor: pointer;
    }
  }

  .tooltip-icon {
    color: var(--color-primary);
    font-size: var(--text-base-size);
    cursor: pointer;
  }
}

.email-tabular {
  background-color: var(--color-white);
  padding: 13px 0px 0px;
  margin-bottom: var(--space-15);
  border-radius: var(--space-5);

  &__heading {
    font-family: 'Open Sans';
    font-size: var(--text-large);
    font-weight: 400;
    line-height: 19px;
    color: var(--color-primary);
    margin-bottom: var(--space-5) !important;
  }

  &__row {
    margin: 0;
    padding: 7px 0px;

    & > div {
      margin-left: -13px;
      margin-right: -13px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-border-table);
    }

    .label-long {
      max-width: 250px;
      padding-right: var(--space-10);
    }
  }

  &__label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: var(--text-base-size-sm);
    line-height: 19px;
    color: var(--color-black);
  }

  &__subLabel {
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
  }

  &__value {
    font-family: 'Open Sans';
    font-size: var(--text-base-size-sm);
    line-height: 19px;
    display: flex;
    align-items: center;

    color: var(--color-black);
  }
  &__smsvalue {
    font-family: 'Open Sans';
    font-size: var(--text-base-size-sm);
    line-height: 19px;
    align-items: center;
    color: var(--color-black);

    .pre-wrap {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
    }
  }

  &__wa-richtext {
    font-size: 14px;
    ul {
      margin-left: 20px;
    }

    ol {
      margin-left: -14px;
    }
  }

  &__consents {
    font-family: 'Open Sans';
    font-size: var(--text-base-size-sm);
    line-height: 19px;
    color: var(--color-black);
    padding-top: 3px;
  }
}

.delete-template-overflow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: left;
  z-index: 1;
  background: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .confirm-mesg-text {
    padding: 0px 15px;
    text-align: center;
  }

  .confirm-btn-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0px 20px;
  }
}

.line-clamp-3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
