/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.wa-design {
  .absolute-label {
    position: relative;
    z-index: 0;
  }
  .wa-buttons-row {
    position: relative;
    background-color: var(--color-table);
    border-radius: 5px;
    &__drag-handle {
      position: absolute;
      left: -23px;
      top: 37px;
      i {
        color: var(--color-grey);
      }
    }
    &.errors {
      border-bottom: 1px solid #edc6c5;
    }
    .row-action {
      position: absolute;
      right: -31px;
      top: 7px;
      z-index: 1;
    }

    .error-col {
      input {
        border: 1px solid var(--color-error);
      }
    }
    .row-action-carousal {
      position: relative;
      right: 50px;
    }
    .col-with-button {
      min-height: 70px;
    }
  }
}

.wa-field-array {
  border: 1px solid var(--color-grey);
  padding: 5px 38px;
  border-radius: 5px;
  border-style: dashed;
  .col-4.form-group {
    min-height: 100px;
  }
}

.wa-field-array-nested {
  border: 1px dashed var(--color-grey);
  padding: 0 0 10px 10px;
  border-radius: 5px;
  .no-button-text {
    text-align: center;
    padding-top: 10px;
  }
  .wa-buttons-row-nested {
    position: relative;

    .row-nested-header {
      position: relative;
      &_text {
        margin: 0 20px;
        font-weight: bold;
      }
    }
    .parent-drag {
      position: absolute;
      left: 0;
      top: 0;
    }
    .child-wrap {
      padding: 10px 10px 10px 50px;
      width: 95%;
      .row-child {
        position: relative;
        margin-bottom: 0.5rem;
        .form-group {
          margin-bottom: 0.3rem;
        }
        .child-drag {
          position: absolute;
          left: -12px;
          top: 0;
          z-index: 1;
        }
        .cw-error {
          margin-top: 0;
          margin-bottom: 0.7rem;
        }
        .btn-primary-link {
          position: absolute;
          right: 16px;
          top: 0;
        }
      }
    }
    .row-action {
      position: absolute;
      right: -31px;
      top: 7px;
      z-index: 1;
      padding-right: 5px;
    }

    .error-col {
      input {
        border: 1px solid var(--color-error);
      }
    }
  }
}

.wa-button-guidelines {
  .unordered-list-item {
    padding-bottom: 10px;
    ul {
      padding-left: 15px;
      padding-top: 2px;
    }
  }
  .item_guide_li {
    padding-bottom: 0.2rem;
  }
  .item_guide_li::before {
    color: var(--color-primary);
    content: '• ';
    font-size: 21px;
    left: -15px;
    position: absolute;
    line-height: 21px;
  }
}

.mobile-device-frame {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  .device-frame {
    width: 320px;
    height: 604px;
    //position: relative !important;
    border-radius: 34px;
    padding: 10px 10px;
    //border: 10px solid #202020;
    background: #202020;
    // display: flex;
    // flex-direction: column;
    //z-index: 9;
  }

  .mobile-device-note {
    color: var(--color-base);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

.screen {
  height: 100%;
}

.screen-container {
  height: 100%;
}

/* Status Bar */

.status-bar {
  height: 25px;
  background: #004e45;
  color: #fff;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 24px 24px 0px 0px;
}

.status-bar:after {
  content: '';
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 8px;
  font-weight: 600;
}

/* Chat */

.chat {
  //height: calc(100% - 69px);
  // flex-grow: 1;
}

.chat-container {
  height: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar:after {
  content: '';
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.user-bar .avatar img {
  //border-radius: 50%;
  //box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
  //background-color: #1f36c7;
}

.user-bar .name {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}

.user-bar .status {
  display: block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  //height: calc(100% - 55px);
  position: relative;
  background-color: #efe7dd;
  background-repeat: repeat;
  //z-index: 0;
  padding-bottom: 5px;
  flex: 1 1 auto;
  min-height: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: 398px;
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 16px;
  margin-bottom: 5px;
  flex: 1 1 auto;

  .date-pin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    .date-pin {
      background: #f6f6f6;
      border-radius: 10px;
      color: #72767a;
      font-size: 12px;
      padding: 8px 8px;
      font-weight: bold;
    }
  }

  .meta-alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    .meta-alert {
      color: #4e7370;
      background-color: #cbf3ef;
      border-color: #cbf4eb;
      //background: #cbf0ee;
      border-radius: 10px;
      //color: #577f7a;
      font-size: 12px;
      padding: 8px 8px;
      //border-color: #bee5eb;
    }
  }
}

.conversation .conversation-container:after {
  content: '';
  display: table;
  clear: both;
}

/* Messages */

.message-body {
  color: #000;
  clear: both;
  line-height: 15px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  //z-index: -1;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  ul {
    margin-left: 16px;
    margin-top: -16px;
    /* margin-block-start: -9px; */
    line-height: 14px;
    margin-bottom: -16px;
  }

  ul:first-child {
    margin-top: -4px;
  }

  ul:last-child {
    margin-bottom: -14px;
  }

  ol {
    margin-left: -18px;
    margin-top: -16px;
    /* margin-block-start: -9px; */
    line-height: 14px;
    margin-bottom: -16px;
  }

  ol:first-child {
    margin-top: -4px;
  }

  ol:last-child {
    margin-bottom: -14px;
  }
}

.message:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.message-container {
  color: #000;
  clear: both;
  line-height: 15px;
  font-size: 12px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  //z-index: -1;
}

.wa-carousel {
  display: flex;
  overflow-x: scroll;
  clear: both;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  align-items: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tempo-chat {
  position: relative;
}
.message-container:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 8px 0 8px;
  position: relative;
  bottom: 0px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message-container.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;

  .header-image-container {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    //width:100%;
    //overflow:hidden;
    .header-img {
      //height: 120px;
      width: 216px;
    }

    &.pad-bottom {
      padding-bottom: 4px;
    }

    .rounded-top {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .rounded-bottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .image-placeholder {
      height: 120px;
      width: 216px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaec;
      color: #cacacc;

      .image-placeholder-icon {
        font-size: 24px;
      }
    }
  }

  .header-video-container {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    //width:100%;
    //overflow:hidden;
    .header-video-wrap {
      position: relative;
    }
    .header-video {
      //height: 120px;
      width: 216px;
    }

    &.pad-bottom {
      padding-bottom: 4px;
    }

    .rounded-top {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .rounded-bottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .image-placeholder {
      height: 120px;
      width: 216px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaec;
      color: #cacacc;

      .image-placeholder-icon {
        font-size: 24px;
      }
    }

    .header-video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      &__play-btn {
        font-size: 32px;
      }
    }
  }

  .header-document-container {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    //width:100%;
    //overflow:hidden;
    .header-doc-wrap {
      background-color: var(--color-grey-2);
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 10px 8px 10px 5px;
      border-radius: 4px;
    }

    .doc-icon {
      margin-right: 5px;
    }

    .doc-image {
      width: 24px;
      height: 24px;
    }

    .doc-info {
      color: #72767a;
      flex: 1;

      .first-text {
        margin-right: 5px;
      }
      .file-info-text {
        position: relative;
        padding-left: 6px;
      }
      .file-info-text::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        background-color: #72767a;
        border-radius: 50%;
      }
      .file-info-text::before {
        left: -4px;
      }

      .file-extn {
        text-transform: uppercase;
      }
    }

    .doc-down {
      margin-left: 5px;
      .doc-down-img {
        width: 22px;
        height: 22px;
      }
    }

    &.pad-bottom {
      padding-bottom: 4px;
    }

    .rounded-top {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .rounded-bottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .header-text-container {
    padding: 8px 8px 0px 8px;
    .header-text {
      font-weight: bold;
    }
  }

  .footer-text-container {
    padding: 0px 8px 0px 8px;
    .footer-text {
      color: #b3ada7;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  .wa-chat-btn-container {
    margin-top: 15px;

    .wa-chat-btn {
      border-top: 1px solid #f1f1f1;
      height: 35px;
      color: #206e8a;
      font-weight: bold;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      padding: 0px 10px;
      .wa-chat-btn-icon {
        margin-right: 10px;
      }
    }

    &.wa-chat-btn-mt-0 {
      margin-top: 0px;
    }
  }

  .wa-lto-container {
    padding: 0px 4px;
    &.mar-top {
      margin-top: 4px;
    }
    .wa-lto-box {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
      padding: 8px 8px;
      &.rounded-top {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &.rounded-bottom {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .wa-lto-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d1eee7;
        color: #27b694;
        margin-right: 8px;
      }

      .wa-lto-info {
        display: flex;
        flex-direction: column;
        .wa-lto-title {
          font-weight: bold;
        }
        .wa-lto-time {
          color: #676968;
        }
        .wa-lto-code {
          color: #a6a8a7;
        }
      }
    }
  }

  &.wa-carousel-mesg {
    float: unset;
    clear: unset;
    margin-right: 4px;
    border-radius: 5px 5px 5px 5px;
    flex-shrink: 0;
    margin-top: 0px;

    &::after {
      border-width: unset;
      border-color: unset;
      top: unset;
      left: unset;
      position: unset;
      content: unset;
      width: unset;
      height: unset;
      border-style: unset;
    }
  }
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -9px;
}

.message-container:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -9px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -9px;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 45px;
  width: 100%;
  //z-index: 2;
  flex-shrink: 0;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 25px 0 0 25px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 14px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 25px 25px 0;
  text-align: center;
  position: relative;
  width: 48px;
}

.conversation-compose .attachment {
  flex: 0 0 auto;
  text-align: center;
  position: relative;
  width: 25px;
}

// .conversation-compose .photo:after {
//   border-width: 0px 0 10px 10px;
//   border-color: transparent transparent transparent #fff;
//   border-style: solid;
//   position: absolute;
//   width: 0;
//   height: 0;
//   content: "";
//   top: 0;
//   right: -10px;
// }

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .attachment i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 5px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
}

.mob-nav-btn {
  height: 50px;
  background: #f6f8fa;
  border-radius: 0px 0px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  position: relative;
  color: #7f7d7e;
}
.fa-size-xl {
  font-size: 1.5em;
}
/* Small Screens */

@media (max-width: 768px) {
  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.wa-template-category-label {
  font-size: 12px;
  font-weight: bold;
}

.mobile-device-overview {
  flex: 0;
  justify-content: flex-start;
  padding-top: 0px;
  align-items: flex-start;

  &.pt-20 {
    padding-top: 20px;
  }
}

.wa-design-sections {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 2px solid var(--color-light-grey);
  border-radius: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fff;
}

.wa-carousel-cross-btn {
  position: absolute;
  right: -52px;
  top: -12px;
  z-index: 1;
}

.wa-design-bg {
  background-color: var(--color-light-grey);
}

.wa-design-section-top {
  padding-top: 20px;
  border-top: 2px solid var(--color-light-grey);
  border-right: 2px solid var(--color-light-grey);
  border-left: 2px solid var(--color-light-grey);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fff;
  margin-bottom: 0px;
  padding-bottom: 1rem;
}

.wa-design-section-bottom {
  border-bottom: 2px solid var(--color-light-grey);
  border-right: 2px solid var(--color-light-grey);
  border-left: 2px solid var(--color-light-grey);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fff;
}

.wa-carousel-table-label {
  color: var(--color-text-grey-1);
}
.wa-carousel-table-wrapper {
  margin-left: 20px;
}

.wa-rich-text-view {
  ul {
    margin-left: 16px;
  }

  ol {
    margin-left: -18px;
  }
}

.cw-modal--lto-guidelines {
  max-width: 900px;
  width: 900px;
  overflow: auto !important;

  .email-overlay-header-box {
    background: transparent;
  }

  .email-overlay-close-modal {
    top: 0;
    right: 0;
  }

  .overlay-content-wrapper {
    position: relative;
    height: 250px;

    .overlay-content {
      padding-right: var(--space-20);
      max-height: 80%;
      overflow: auto;
    }

    .bottom-cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-right: var(--space-20);
    }
  }
}

.tinymce-error {
  border: 2px solid var(--color-error) !important;
}
.drop-down-icon {
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
}

.variable-dropdown {
  padding-left: 0;
  z-index: 99;
}

.variable-dropdown-select {
  width: 300px;
}
.variable-dropdown {
  position: relative;
  .btn-outline-secondary {
    height: 38px;
    position: absolute;
    left: 300px;
    top: 0;
    z-index: 10;
  }
}
.variable-dropdown-wrap {
  .dynamic-variables {
    width: 95%;
  }
  .btn-circle.btn-remove {
    width: 19px;
    height: 19px;
    margin-top: 9px;
  }
}
.carousal-alert-text {
  font-size: 14px;
}

.remove-btn {
  top: 10%;
  left: 92%;
}

.location-bottom-container {
  padding: 8px 8px 0px 8px;
  .header-text {
    font-weight: bold;
  }
  background-color: #f2f2f2;
}

.email-card {
  .mobile-device-frame {
    .device-frame {
      width: 260px;
      .user-bar {
        z-index: unset;
      }
    }
  }
}
.wa-template {
  &__card {
    border-radius: 35px;
  }
}
